import * as QR from 'avenger/lib/QueryResult';
import ErrorPage from '#pages/ErrorPage';
import UserWithoutScope from '#pages/UserWithoutScope';
import IntlProvider from '#providers/IntlProvider';
import { getUserInfo, isSessionValid } from '#queries';
import { useBrowserSupportedLang } from '#util/intl';
import { useQueries } from 'avenger/lib/react';
import { pipe } from 'fp-ts/function';
import { Route, RouteComponentProps } from 'react-router-dom';
import Pending from '@banksealer/shared/commons/Pending';
import RouteLoader from './RouteLoader';
import { loadLocale } from './loadLocale';
import SessionExpired from './sessionExpired';

const ErrorPageWithIntl = ({ errorResponse }: { errorResponse: unknown }) => {
  const lang = useBrowserSupportedLang();
  return (
    <IntlProvider loadLocale={loadLocale} locale={lang}>
      <ErrorPage errorResponse={errorResponse} />
    </IntlProvider>
  );
};

export default ({ component: Component, restricted, disableFetchIntl = false, ...rest }: any) => {
  return pipe(
    useQueries({ isSessionValid, getUserInfo }),
    QR.fold(
      () => <Pending />,
      (e) => <ErrorPageWithIntl errorResponse={e} />,
      ({ isSessionValid, getUserInfo }) => {
        const _Component = (props: RouteComponentProps) => {
          if (!isSessionValid) {
            return <SessionExpired />;
          }
          if (getUserInfo.scopes.length == 0) {
            return <UserWithoutScope />;
          }
          return <Component {...props} />;
        };

        return (
          <Route
            {...rest}
            render={(props) => (
              <RouteLoader
                component={<_Component {...props} />}
                providerType={'private'}
                routeProps={props}
                scopes={getUserInfo.scopes}
                scopeGroups={getUserInfo.scopeGroups}
              />
            )}
          />
        );
      }
    )
  );
};
