import { Option, fold } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import React from 'react';
import Header from '@banksealer/shared/commons/Header';
import './topbar.scss';

type Props = {
  logo: Option<string>;
  logoHeader: JSX.Element;
  menu: JSX.Element;
};
export default ({ logo, logoHeader, menu }: Props) => {
  const logoCmp = React.useMemo(() => {
    return pipe(
      logo,
      fold(
        () => null,
        (base64Logo) => <img src={base64Logo} className="header-logo mx-2" />
      )
    );
  }, [logo]);

  return (
    <Header
      useNav={false}
      classNameRow={{ 'justify-content-between': true }}
      className={{ 'px-0': true, 'py-1': true, topbar: true }}
    >
      <>
        <div className="col-2 d-flex align-items-center h-100">
          {logoHeader}
          {logoCmp}
        </div>
        <div className="col-xl-2 col-6 h-100 align-self-end">{menu}</div>
      </>
    </Header>
  );
};
