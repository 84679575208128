import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import Pending from '@banksealer/shared/commons/Pending';
import '@banksealer/shared/theme';
import Routes from './routes';
import './setup/addDeviceClassName';
import browserCompatibility from './setup/browserCompatibility';

const UnsupportedBroswer = () => (
  <div id="unsupportedBroswer">
    <div id="logo"></div>
    <h2>The current browser is NOT supported.</h2>
    <p>Please switch to a recent version of Google Chrome, Safari, Edge or IE 11</p>
  </div>
);
const App = () => (
  <React.StrictMode>
    <React.Suspense fallback={<Pending />}>
      <BrowserRouter>
        <Switch>
          <Routes />
        </Switch>
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>
);

ReactDOM.render(
  browserCompatibility() ? <App /> : <UnsupportedBroswer />,
  document.getElementById('app')
);