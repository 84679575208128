import { ActionsMenu, Menu } from 'buildo-react-components/lib/Menu';
import React from 'react';
import './menu.scss';

export type MenuOption = ActionsMenu.Option;

export default (props: Menu.Props) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && props.isOpen) {
        props.onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, props]);

  return (
    <div ref={wrapperRef}>
      <Menu {...props} />
    </div>
  );
};
