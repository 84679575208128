import { OnFoldType } from '#models';
import { Either, fold } from 'fp-ts/Either';
import { TaskEither } from 'fp-ts/TaskEither';
import { flow } from 'fp-ts/function';

export const runTaskEither =
  <ReturnType = unknown, TaskReturnType = ReturnType>(
    taskEither: TaskEither<unknown, TaskReturnType>
  ) =>
  <L, R>(onLeft: OnFoldType<L, R>, onRight: OnFoldType<TaskReturnType, R>) =>
    flow<
      [taskeither: TaskEither<unknown, TaskReturnType>],
      Promise<Either<unknown, TaskReturnType>>,
      R
    >(
      (taskEither) => taskEither(),
      //@ts-ignore
      (p) => p.then(fold(onLeft, onRight))
    )(taskEither);
