import * as Api from '#API';
import { GroupId, PermissionId, UserId, UserLoginType } from '#metarpheus/model-ts';
import { getClients, getGroups, getUsers } from '#queries';
import { command } from 'avenger';
import { chainFromToken } from './commands';

export const createUser = command(
  ({
    username,
    email,
    groupId,
    userType,
    permissions,
  }: {
    username: string;
    email: string;
    groupId?: GroupId;
    userType: UserLoginType;
    permissions: Array<PermissionId>;
  }) =>
    chainFromToken((token) =>
      Api.createUser(username, email, 'password', userType, permissions, token, groupId)
    ),
  { getUsers }
);

export const editUser = command(
  ({
    userId,
    newGroupId,
    userType,
    permissions,
  }: {
    userId: UserId;
    newGroupId?: GroupId;
    userType: UserLoginType;
    permissions: Array<PermissionId>;
  }) => chainFromToken((token) => Api.editUser(userId, userType, permissions, token, newGroupId)),
  { getUsers }
);

export const resetPassword = command(({ userId, password }: { userId: UserId; password: string }) =>
  chainFromToken((token) => Api.resetPassword(userId, password, token))
);

export const deleteUser = command(
  (userId: UserId) => chainFromToken((token) => Api.deleteUser(userId, token)),
  { getUsers }
);

export const createGroup = command(
  ({ name, permissions }: { name: string; permissions: Array<PermissionId> }) =>
    chainFromToken((token) => Api.createGroup(name, permissions, token)),
  { getGroups }
);

export const editGroup = command(
  ({ groupId, permissions }: { groupId: GroupId; permissions: Array<PermissionId> }) =>
    chainFromToken((token) => Api.editGroup(groupId, permissions, token)),
  { getGroups }
);

export const deleteGroup = command(
  ({ groupId }: { groupId: GroupId }) => chainFromToken((token) => Api.deleteGroup(groupId, token)),
  { getGroups }
);

export const registerClient = command(
  ({ permissions, userType }: { permissions: Array<PermissionId>; userType: UserLoginType }) =>
    chainFromToken((token) => Api.registerClient(userType, permissions, token)),
  { getClients }
);

export const unregisterClient = command(
  ({ clientId }: { clientId: string }) =>
    chainFromToken((token) => Api.unregisterClient(clientId, token)),
  { getClients }
);

export const changeClientPermissions = command(
  ({ clientId, permissions }: { clientId: UserId; permissions: Array<PermissionId> }) =>
    chainFromToken((token) => Api.changeClientPermissions(clientId, permissions, token)),
  { getClients }
);
