import * as QR from 'avenger/lib/QueryResult';
import { setActiveScope } from '#commands';
import { config } from '#config';
import { UserScope } from '#metarpheus/model-ts';
import { getActiveScope, getUserInfo } from '#queries';
import { runTaskEither } from '#util/fpTs';
import { isAdminRoute, isUserViewRoute } from '#util/route';
import { useQueries } from 'avenger/lib/react';
import { right } from 'fp-ts/TaskEither';
import { fold } from 'fp-ts/boolean';
import { constVoid, pipe } from 'fp-ts/function';
import _chunk from 'lodash/chunk';
import _flatten from 'lodash/flatten';
import React from 'react';
import { IoBriefcase, IoChevronForwardCircleSharp, IoPerson } from 'react-icons/io5';
import { useIntl } from 'react-intl';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ShowToastMessage from '@banksealer/shared/commons/ShowToastMessage';
import MenuUser from '@banksealer/shared/commons/brc/Menu';
import { MenuOption } from '@banksealer/shared/commons/brc/Menu/Menu';
import { ReactComponent as Burger } from '@banksealer/shared/theme/assets/bars.svg';
import './sidebar.scss';

type Props = {
  scopes: Array<UserScope>;
  activeScope: string;
  activeScopeGroup?: string;
  actualRoute: string;
  userViews: string[];
};

const Sidebar = ({ scopes, activeScope, actualRoute, userViews }: Props) => {
  const history = useHistory();
  const intl = useIntl();
  const [collapsed, toggleCollapse] = React.useState(true);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const changeScope = React.useCallback(
    (newScope: string) => {
      pipe(
        activeScope === newScope,
        fold(
          () => setActiveScope(newScope),
          () => right(constVoid())
        ),
        runTaskEither,
        (t) =>
          t(
            () => ShowToastMessage({ message: 'Error.Generic', type: 'error' }),
            () => history.push(generatePath(config.routesPath.SCOPE, { scope: newScope }))
          )
      );
    },
    [history]
  );
  const gotoUserView = React.useCallback(
    (scopeGroup: string) => {
      pipe(setActiveScope('no-scope'), runTaskEither, (t) =>
        t(
          () => ShowToastMessage({ message: 'Error.Generic', type: 'error' }),
          () =>
            history.push(generatePath(config.routesPath.USER_VIEW_LIST, { scopeGroup: scopeGroup }))
        )
      );
    },
    [history]
  );

  const setActiveScopeClass = React.useCallback(
    (scope: string, activeScope: string) => {
      if (isAdminRoute(actualRoute) || isUserViewRoute(actualRoute)) {
        return;
      }
      return activeScope === scope ? 'active' : '';
    },
    [actualRoute, isAdminRoute]
  );

  const [scopesViewed, scopesInDropdown] = React.useMemo(() => {
    const [first = [], ...chunks] = _chunk(scopes, 4);
    const oth = _flatten(chunks);
    return [first, oth];
  }, [scopes]);

  const options: Array<MenuOption> = React.useMemo(
    () =>
      scopesInDropdown.map((scope) => ({
        title: intl.formatMessage({ id: `Scope.${scope.scope}.plural` }),
        type: 'item',
        onClick: () => changeScope(scope.scope),
        selected: setActiveScopeClass(scope.scope, activeScope) === 'active',
      })),
    [scopesInDropdown]
  );

  return (
    <>
      <ReactTooltip effect="solid" place="right" id="sidebar-tooltip" />
      <aside
        id="sidebar-container"
        className={`d-inline-block ${collapsed ? 'sidebar-collapsed' : 'sidebar-expanded'}`}
      >
        <div className={`sidebar-list ${collapsed ? '' : 'expanded'}`}>
          <ul className="list-group h-100">
            <li className="burger-icon use-pointer" onClick={() => toggleCollapse(!collapsed)}>
              <Burger title="" />
            </li>
            {userViews.map((subject) => (
              <>
                <li
                  className="menu-item use-pointer"
                  onClick={() => gotoUserView(subject)}
                  data-tip={intl.formatMessage({ id: `User.${subject}.plural` })}
                  data-for="sidebar-tooltip"
                >
                  <IoPerson size="md" />
                  <span className="menu-item-label ellipsis multilines">
                    {intl.formatMessage({ id: `User.${subject}.plural` })}
                  </span>
                </li>
              </>
            ))}
            {userViews.length > 0 ? (
              <li>
                <div className="separator" />
              </li>
            ) : (
              <></>
            )}
            {scopesViewed.map(({ scope }, key) => {
              const scopeLabel = intl.formatMessage({ id: `Scope.${scope}.plural` });
              return (
                <li
                  className={`menu-item ${setActiveScopeClass(scope, activeScope)} use-pointer `}
                  onClick={() => changeScope(scope)}
                  key={key}
                  data-tip={collapsed ? scopeLabel : ''}
                  data-for="sidebar-tooltip"
                >
                  <IoBriefcase size="md" />
                  <span className="menu-item-label ellipsis multilines">{scopeLabel}</span>
                </li>
              );
            })}
            {options.length > 0 && (
              <li
                className={`menu-item use-pointer ${
                  options.some((_) => _.selected) ? 'active' : ''
                }`}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <MenuUser
                  onOpen={() => setMenuOpen(!menuOpen)}
                  onClose={() => setMenuOpen(!menuOpen)}
                  isOpen={menuOpen}
                  options={options}
                  className="menu-scopes"
                  size="small"
                >
                  <IoChevronForwardCircleSharp size="md" />
                </MenuUser>
                <span className="menu-item-label ellipsis multilines">open</span>
              </li>
            )}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default () => {
  const { pathname } = useLocation();
  return pipe(
    useQueries({ getUserInfo, getActiveScope }),
    QR.fold(
      () => <div>...</div>,
      (e) => {
        if (e === 'no scope') {
          return null;
        }
        return <ShowToastMessage message="Error.generic" type="success" />;
      },
      ({ getUserInfo, getActiveScope: activeScope }) => {
        const { scopes, scopeGroups } = getUserInfo;
        return (
          <Sidebar
            activeScope={activeScope}
            scopes={scopes}
            actualRoute={pathname}
            userViews={scopeGroups.filter((v) => v.hasUserView).map((v) => v.subjectOfAnalysis)}
          />
        );
      }
    )
  );
};
