import { AxiosError } from 'axios';
import React from 'react';
import FlexView from 'react-flexview/lib';
import { useIntl } from 'react-intl';
import { ReactComponent as LogoError } from '@banksealer/shared/theme/assets/logoError.svg';
import './errorPage.scss';

export default (errorDetect: (...props: any) => void, isWhiteLabel = false) =>
  ({
    errorResponse = {},
    status = 500,
  }: {
    errorResponse?: unknown | AxiosError;
    status?: number;
  }) => {
    const intl = useIntl();

    const { response: { status: errorResponseStatusCode = status } = {}, customError = false } =
      errorResponse as AxiosError & { customError: boolean };
    React.useEffect(() => {
      errorDetect(errorResponseStatusCode, intl, customError);
    }, [customError, errorResponseStatusCode, intl]);

    const text = intl.formatMessage({ id: `Error.${errorResponseStatusCode}` });
    const textError = intl.formatMessage({
      id: `Error.${errorResponseStatusCode}Message`,
    });
    return (
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-12 col-md-6">
            <FlexView column className="justify-content-center h-100 text-error text-start">
              <div className="mx-auto">
                <h1>{errorResponseStatusCode}</h1>
                <p>{text}</p>
                <br />
                <small dangerouslySetInnerHTML={{ __html: textError }} />
              </div>
            </FlexView>
          </div>
          <div className="col-md-6 d-none d-md-block" style={{ backgroundColor: 'white' }}>
            <FlexView
              className="align-content-center justify-content-center"
              height="100%"
              width="100%"
            >
              {
                !isWhiteLabel ? (
                  <LogoError className="img-fluid" title="" />
                ) : <></>
              }
            </FlexView>
          </div>
        </div>
      </div>
    );
  };