import { useIntl } from 'react-intl';
import { TypeOptions, toast } from 'react-toastify';

type Props = {
  message: string;
  type: TypeOptions;
};
const ShowToastMessage = (props: Props) => {
  const intl = useIntl();
  const { message, type } = props;
  showToastMessageFn(intl.formatMessage({ id: message }), type);
  return null;
};

export const showToastMessageFn = (message: string, type: TypeOptions, options?: object) => {
  toast(
    message,
    Object.assign(
      {},
      {
        type: type,
      },
      options
    )
  );
};
export default ShowToastMessage;
