import * as API from '#API';
import { Auth, FraudulentType, OrderByField, Risk } from '#metarpheus/model-ts';
import { getScopeTableHeight as _getScopeTableHeight } from '#util/agGrid/agGrid';
import { useBrowserSupportedLang } from '#util/intl';
import {
  available,
  compose,
  expire,
  param,
  product,
  queryShallow,
  queryStrict,
  refetch,
} from 'avenger';
import { of } from 'fp-ts/IO';
import { TaskEither, filterOrElse, fromIO } from 'fp-ts/TaskEither';
import { flow } from 'fp-ts/function';

export const getToken = () => Auth.encode(window.localStorage.getItem('AUTH_TOKEN') || '');

export const getActiveScope = queryStrict(
  flow<[], TaskEither<string, string>, TaskEither<string, string>>(
    () => fromIO<string, string>(of(window.sessionStorage.getItem('activeScope') || '')),
    filterOrElse(
      (scope) => scope !== '',
      () => 'no scope'
    )
  ),
  available
);

export const isSessionValid = queryStrict(() => API.isSessionValid(getToken()), refetch);

export const getUserInfo = queryStrict(() => API.getUserInfo(getToken()), available);

export const getTimeWindow = compose(
  product({
    scope: param<string>(),
  }),
  queryShallow(({ scope }) => API.getTimeWindow(getToken(), scope), refetch)
);

export const getScopeConfig = compose(
  product({
    scope: param<string>(),
  }),
  queryShallow(
    ({ scope }) => API.getScopeConfig(getToken(), scope, useBrowserSupportedLang()),
    available
  )
);

export const getUserViewConfig = compose(
  product({
    scopeGroup: param<string>(),
  }),
  queryShallow(
    ({ scopeGroup }) => API.getUserViewConfig(getToken(), useBrowserSupportedLang(), scopeGroup),
    available
  )
);

export const getScopeTransactions = compose(
  product({
    scope: param<string>(),
    risk: param<Risk>(),
    fraudulentType: param<FraudulentType>(),
    limit: param<number>(),
    offset: param<number>(),
    orderBy: param<OrderByField[] | undefined>(),
    userId: param<string | undefined>(),
  }),
  queryShallow(
    ({ scope, risk, fraudulentType, limit, offset, orderBy, userId }) =>
      API.getScopeTransactions({
        token: getToken(),
        scope,
        limit,
        offset,
        risk,
        fraudulentType,
        orderBy,
        userId,
      }),
    refetch
  )
);

export const doSearch = compose(
  product({
    scope: param<string>(),
    orderBy: param<OrderByField[] | undefined>(),
    query: param<string>(),
    offset: param<number>(),
    groupByField: param<string | undefined>(),
    limit: param<number | undefined>(),
  }),
  queryShallow(
    ({ scope, orderBy = [], query, offset, limit, groupByField = undefined }) =>
      API.doSearch({
        scope,
        token: getToken(),
        query,
        limit,
        groupByField,
        orderBy,
        offset,
      }),
    refetch
  )
);

export const getScopeTransaction = compose(
  product({
    scope: param<string>(),
    id: param<string>(),
  }),
  queryShallow(({ scope, id }) => API.getScopeTransaction(getToken(), scope, id), refetch)
);

export const getTransactionContribution = compose(
  product({
    scope: param<string>(),
    id: param<string>(),
  }),
  queryShallow(({ scope, id }) => API.getTransactionContribution(getToken(), id, scope), refetch)
);

export const getStaticRules = compose(
  product({
    scope: param<string>(),
  }),
  queryShallow(({ scope }) => API.getStaticRules(getToken(), scope), refetch)
);

export const getStatistics = compose(
  product({
    scope: param<string>(),
  }),
  queryShallow(
    ({ scope }) => API.getStatistics(getToken(), scope, useBrowserSupportedLang()),
    refetch
  )
);

export const getHistogramFeature = compose(
  product({
    scope: param<string>(),
    customerId: param<string>(),
    feature: param<string>(),
  }),
  queryShallow(
    ({ scope, customerId, feature }) =>
      API.getHistogramFeature(getToken(), scope, customerId, feature),
    expire(2000)
  )
);

export const getUserTemporalFlags = compose(
  product({
    scopeGroup: param<string>(),
    userId: param<string>(),
  }),
  queryShallow(
    ({ scopeGroup, userId }) => API.getTemporalFlags(getToken(), scopeGroup, userId),
    refetch
  )
);

export const getBobafettUserInfo = compose(
  product({
    scopeGroup: param<string>(),
    userId: param<string>(),
  }),
  queryShallow(
    ({ scopeGroup, userId }) => API.getBobafettUserInfo(getToken(), scopeGroup, userId),
    refetch
  )
);

export const getAllUsers = compose(
  product({
    onlyFlagged: param<boolean>(),
    offset: param<number>(),
    limit: param<number>(),
    scopeGroup: param<string>(),
    userSearchPattern: param<string | undefined>(),
  }),
  queryShallow(
    ({ onlyFlagged, offset, limit, scopeGroup, userSearchPattern }) =>
      API.getAllUsers(getToken(), onlyFlagged, offset, limit, scopeGroup, userSearchPattern),
    refetch
  )
);

export const getTemporalTimeWindow = queryShallow(
  () => API.getTemporalTimeWindow(getToken()),
  available
);

export const getFrontendConfig = queryShallow(API.getFrontendConf, available);

export const getFeatures = compose(
  product({
    scope: param<string>(),
  }),
  queryShallow(({ scope }) => API.getFeatures(getToken(), scope), available)
);
