import * as QR from 'avenger/lib/QueryResult';
import { FrontendUserViewConfiguration } from '#metarpheus/model-ts';
import { UserInfoIntlProvider } from '#models/providers';
import ErrorPage from '#pages/ErrorPage';
import QueryIntlProvider from '#providers/QueryIntlProvider';
import { getUserViewConfig } from '#queries';
import { useQuery } from 'avenger/lib/react';
import { pipe } from 'fp-ts/function';
import React from 'react';
import { IntlProvider } from 'react-intl';

export default (props: UserInfoIntlProvider) => {
  const intlData = React.useMemo(() => props.loadLocale(props.locale), []);
  return pipe(
    useQuery(getUserViewConfig, { scopeGroup: props.scopeGroup }),
    QR.fold(
      () => {
        return null;
      },
      (e: unknown) => {
        return (
          <IntlProvider {...intlData}>
            <ErrorPage errorResponse={e} />
          </IntlProvider>
        );
      },
      (getUserConfig: FrontendUserViewConfiguration) => {
        return (
          <QueryIntlProvider
            intlData={intlData}
            queryResult={getUserConfig}
            translations={{ ...getUserConfig.translations, ...props.additionalTransalations }}
            locale={props.locale}
            children={props.children}
          />
        );
      }
    )
  );
};
