import FlexView from 'react-flexview';
import { GridSpinner } from 'react-spinners-kit';
import './pending.scss';

type Props = {
  noBg?: boolean;
  size?: number;
};
export default ({ noBg = false, size = 100 }: Props) => {
  const className = `loading ${noBg ? 'noBg' : ''}`;
  return (
    <FlexView className={className} height="100%" width="100%">
      <GridSpinner size={size} color="#131948" sizeUnit="px" />
    </FlexView>
  );
};
