import { config } from '#config';
import ErrorPage from '#pages/ErrorPage';
import { ActionsContextProvider } from '#providers/ActionsProvider';
import { ActiveScopeContextProvider } from '#providers/ActiveScopeProvider';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackofficeRoute from './backofficeRoute';
import PrivateRoute from './setup/privateRoute';
import PublicRoute from './setup/publicRoute';
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import BSIcon from "@banksealer/shared/theme/assets/banksealer-favicon.png";
import WLIcon from "@banksealer/shared/theme/assets/white-label-favicon.png";

const CommonComponents = ({ children }: { children: any }) => {
  const favicon = config.whiteLabel ? WLIcon : BSIcon
  console.log(favicon, config.whiteLabel)
  return (
    <>
      <Helmet>
        <title>{config.title}</title>
        <meta property="og:title" content={config.title} />
        <meta property="og:description" content={config.description} />
        <meta name="description" content={config.description} />
        <link rel="icon" href={favicon} />
        {/* {
          !config.whiteLabel ? (<>
            <link rel="apple-touch-icon" sizes="57x57" href="/assets/favicon/apple-icon-57x57.png?v=2" />
            <link rel="apple-touch-icon" sizes="60x60" href="/assets/favicon/apple-icon-60x60.png?v=2" />
            <link rel="apple-touch-icon" sizes="72x72" href="/assets/favicon/apple-icon-72x72.png?v=2" />
            <link rel="apple-touch-icon" sizes="76x76" href="/assets/favicon/apple-icon-76x76.png?v=2" />
            <link rel="apple-touch-icon" sizes="114x114" href="/assets/favicon/apple-icon-114x114.png?v=2" />
            <link rel="apple-touch-icon" sizes="120x120" href="/assets/favicon/apple-icon-120x120.png?v=2" />
            <link rel="apple-touch-icon" sizes="144x144" href="/assets/favicon/apple-icon-144x144.png?v=2" />
            <link rel="apple-touch-icon" sizes="152x152" href="/assets/favicon/apple-icon-152x152.png?v=2" />
            <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicon/apple-icon-180x180.png?v=2" />
            <link rel="icon" type="image/png" sizes="192x192" href="/assets/favicon/android-icon-192x192.png?v=2" />
            <link rel="manifest" href="/assets/favicon/manifest.json?v=2" />
            <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicon/favicon-32x32.png?v=2" />
            <link rel="icon" type="image/png" sizes="96x96" href="/assets/favicon/favicon-96x96.png?v=2" />
            <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicon/favicon-16x16.png?v=2" />
            <meta name="msapplication-TileImage" content="/assets/favicon/ms-icon-144x144.png?v=2" />
            <meta name="msapplication-TileColor" content="#131948" />
            <meta name="theme-color" content="#131948" />
          </>) : null
        } */}
      </Helmet>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick={false}
        draggable={false}
        pauseOnHover={true}
      />
      {children}
    </>
  );
};

const FrontendRoutes = () => [
  <Route path="/" exact key={'/'}>
    <Redirect to={config.routesPath.LOGIN} />
  </Route>,
  <PublicRoute
    path={config.routesPath.AZURE_REDIRECT}
    exact
    component={React.lazy(() => import('#pages/AzureLogin'))}
    key={config.routesPath.AZURE_REDIRECT}
  />,
  <PublicRoute
    path={config.routesPath.LOGIN}
    exact
    component={React.lazy(() => import('#pages/Login'))}
    key={config.routesPath.LOGIN}
  />,

  <PublicRoute
    path={config.routesPath.FORGOT_PASSWORD}
    exact
    component={React.lazy(() => import('#pages/ForgotPassword'))}
    key={config.routesPath.FORGOT_PASSWORD}
  />,
  <PublicRoute
    path={config.routesPath.CHANGE_PASSWORD}
    exact
    component={React.lazy(() => import('#pages/ChangePassword'))}
    key={config.routesPath.CHANGE_PASSWORD}
  />,
  <PrivateRoute
    component={React.lazy(() => import('#pages/LoginSuccess'))}
    path={config.routesPath.LOGIN_SUCCESS}
    exact
    disableFetchIntl
    key={config.routesPath.LOGIN_SUCCESS}
  />,
  <PrivateRoute
    component={React.lazy(() => import('#pages/Scope'))}
    path={config.routesPath.SCOPE}
    exact
    key={config.routesPath.SCOPE}
  />,
  <PrivateRoute
    component={React.lazy(() => import('#pages/Search'))}
    path={config.routesPath.SEARCH}
    exact
    key={config.routesPath.SEARCH}
  />,
  <PrivateRoute
    component={React.lazy(() => import('#pages/Detail'))}
    path={config.routesPath.DETAIL}
    exact
    key={config.routesPath.DETAIL}
  />,

  <PrivateRoute
    component={React.lazy(() => import('#pages/Dashboard'))}
    path={config.routesPath.DASHBOARD}
    exact
    key={config.routesPath.DASHBOARD}
  />,
  <PrivateRoute
    component={React.lazy(() => import('#pages/StaticRules'))}
    path={config.routesPath.STATIC_RULES}
    exact
    key={config.routesPath.STATIC_RULES}
  />,
  <PrivateRoute
    component={React.lazy(() => import('#pages/UserViewList'))}
    path={config.routesPath.USER_VIEW_LIST}
    exact
    key={config.routesPath.USER_VIEW_LIST}
  />,
  <PrivateRoute
    component={React.lazy(() => import('#pages/UserViewDetail'))}
    path={config.routesPath.USER_VIEW_DETAIL}
    exact
    key={config.routesPath.USER_VIEW_DETAIL}
  />,
];

const BackofficeRoutes = () => [
  <Route path={config.routesPath.BACKOFFICE_BASE} exact key={config.routesPath.BACKOFFICE_BASE}>
    <Redirect to={config.routesPath.BACKOFFICE_AUDITS} />
  </Route>,
  <BackofficeRoute
    path={config.routesPath.BACKOFFICE_GROUPS}
    exact
    component={React.lazy(() => import('#pages/Backoffice/Groups'))}
    key={config.routesPath.BACKOFFICE_GROUPS}
  />,
  <BackofficeRoute
    path={config.routesPath.BACKOFFICE_AUDITS}
    exact
    component={React.lazy(() => import('#pages/Backoffice/Audits'))}
    key={config.routesPath.BACKOFFICE_AUDITS}
  />,
  <BackofficeRoute
    path={config.routesPath.BACKOFFICE_USERS}
    exact
    component={React.lazy(() => import('#pages/Backoffice/Users'))}
    key={config.routesPath.BACKOFFICE_USERS}
  />,
  <BackofficeRoute
    path={config.routesPath.BACKOFFICE_CLIENTS}
    exact
    component={React.lazy(() => import('#pages/Backoffice/Clients'))}
    key={config.routesPath.BACKOFFICE_CLIENTS}
  />,
  <BackofficeRoute
    path={config.routesPath.BACKOFFICE_SWAGGER}
    exact
    component={React.lazy(() => import('#pages/Backoffice/SwaggerIntegration'))}
    key={config.routesPath.BACKOFFICE_SWAGGER}
  />,
];
export default () => {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <ActiveScopeContextProvider>
        <ActionsContextProvider>
          <CommonComponents>
            <Switch>
              <Route path={config.routesPath.HEALTH} exact>
                "OK"
              </Route>

              {BackofficeRoutes()}
              {FrontendRoutes()}
              <PublicRoute
                path="*"
                component={() => (
                  <>
                    <ErrorPage status={404} />
                  </>
                )}
              />
            </Switch>
          </CommonComponents>
        </ActionsContextProvider>
      </ActiveScopeContextProvider >
    </QueryClientProvider>
  );
};