import Layout from '#commons/Layout';
import FlexView from 'react-flexview';
import { useIntl } from 'react-intl';
import { ReactComponent as LogoError } from '@banksealer/shared/theme/assets/logoError.svg';
import { config } from '#config';

export default () => {
  const intl = useIntl();
  return (
    <Layout>
      <div className="container-fluid px-3 d-block" style={{ height: 'calc(100vh - 54px)' }}>
        <div className="row h-100">
          <div className="col-12 col-md-6">
            <FlexView column className="justify-content-center h-100 text-error text-start">
              <div className="mx-auto">
                <h3>{intl.formatMessage({ id: 'Error.UserWithourScope' })}</h3>
                <br />
              </div>
            </FlexView>
          </div>
          <div className="col-md-6 d-none d-md-block" style={{ backgroundColor: 'white' }}>
            <FlexView
              className="align-content-center justify-content-center"
              height="100%"
              width="100%"
            >
              {
                !config.whiteLabel ? (
                  <LogoError className="img-fluid" title="" />
                ) : <></>
              }
            </FlexView>
          </div>
        </div>
      </div>
    </Layout>
  );
};