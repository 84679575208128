import { config } from '#config';
import { matchPath } from 'react-router';

export const isAdminRoute = (activeRoute: string) =>
  matchPath(activeRoute, {
    path: [
      config.routesPath.BACKOFFICE_AUDITS,
      config.routesPath.BACKOFFICE_GROUPS,
      config.routesPath.BACKOFFICE_USERS,
    ],
    exact: true,
    strict: true,
  }) !== null;

export const isUserViewRoute = (activeRoute: string) =>
  matchPath(activeRoute, {
    path: [config.routesPath.USER_VIEW_DETAIL, config.routesPath.USER_VIEW_LIST],
    exact: true,
    strict: true,
  }) !== null;

export const isActiveRoute = (route: string, activeRoute: string) =>
  matchPath(activeRoute, { path: route, exact: true, strict: true }) !== null;
