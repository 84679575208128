import { config } from '#config';
import { ActiveScopeProviderType } from '#models/providers';
import { constVoid } from 'fp-ts/function';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

export const ActiveScopeProvider = React.createContext<ActiveScopeProviderType>({
  activeScope: '',
  setActiveScope: constVoid,
});

export const ActiveScopeContextProvider = (props: { children: React.ReactElement }) => {
  const { params: { scope } = {} as any } =
    useRouteMatch(Object.values(config.routesPath)) || ({} as any);

  const [activeScope, setActiveScope] = React.useState(() => {
    if (!scope) {
      return window.sessionStorage.getItem('activeScope') || 'no scope';
    }
    return scope;
  });
  React.useEffect(() => {
    setActiveScope(scope);
  }, [scope]);
  const children = React.useMemo(() => props.children, [props]);

  return (
    <ActiveScopeProvider.Provider value={{ activeScope, setActiveScope }}>
      {children}
    </ActiveScopeProvider.Provider>
  );
};
