import * as QR from 'avenger/lib/QueryResult';
import { doLogout } from '#commands';
import { config } from '#config';
import { getUserInfo } from '#queries';
import { getAzureLogoutApi } from '#util/azure';
import { runTaskEither } from '#util/fpTs';
import { isAdminRoute as _isAdminRoute } from '#util/route';
import { useQueries } from 'avenger/lib/react';
import { Option } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import React from 'react';
import { IoCaretDown } from 'react-icons/io5';
import { useIntl } from 'react-intl';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import TopBarShared from '@banksealer/shared/commons/Topbar';
import MenuUser from '@banksealer/shared/commons/brc/Menu';
import { MenuOption } from '@banksealer/shared/commons/brc/Menu/Menu';
import _Logoheader from '@banksealer/shared/theme/assets/logo-header.png';
import UserIcon from '@banksealer/shared/theme/assets/user-icon.png';

type Props = {
  isAdmin: boolean;
  username: string;
  logo: Option<string>;
  userType: string;
};
const Topbar = ({ isAdmin, username, logo, userType }: Props) => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const history = useHistory();
  const isAdminRoute = React.useCallback(_isAdminRoute, [pathname]);

  const options: Array<MenuOption> = [
    {
      title: intl.formatMessage({ id: 'Logout' }),
      type: 'item',
      onClick: () => {
        pipe(runTaskEither(doLogout({})), (f) =>
          f(
            () => {
              toast.error(intl.formatMessage({ id: 'Error.generic' }));
            },
            () => {
              window.sessionStorage.clear();
              toast.success(intl.formatMessage({ id: 'Logout.complete' }));
              if (userType === 'Azure') {
                window.location.replace(getAzureLogoutApi());
              } else {
                history.push(config.routesPath.LOGIN);
              }
            }
          )
        );
      },
    },
  ];
  if (isAdmin && !isAdminRoute(pathname)) {
    options.unshift({
      title: intl.formatMessage({ id: 'Backoffice' }),
      type: 'item',
      onClick: () => {
        history.push(config.routesPath.BACKOFFICE_BASE);
      },
    });
  }

  const menu = (
    <div className="menu-user">
      <MenuUser
        onOpen={() => setMenuOpen(!menuOpen)}
        onClose={() => setMenuOpen(!menuOpen)}
        isOpen={menuOpen}
        options={options}
        size="small"
      >
        <div className="d-flex flex-row">
          <img
            src={UserIcon}
            className="img-fluid"
            style={{ maxHeight: '35px', paddingRight: '5px' }}
          />
          <span className="menu-item-label ellipsis mr-1">
            <span className="menu-item-user c-primary">{username} </span>
            <IoCaretDown className="dropdownIcon" />
          </span>
        </div>
      </MenuUser>
    </div>
  );
  const Logoheader = !config.whiteLabel ? (
    <a href={generatePath(config.routesPath.LOGIN)} className="header-logo d-contents">
      <img src={_Logoheader} className="header-logo" />
    </a>
  ) : <></>;
  return <TopBarShared logo={logo} logoHeader={Logoheader} menu={menu} />;
};

export default () =>
  pipe(
    useQueries({ getUserInfo }),
    QR.fold(
      () => <div>...</div>,
      () => null,
      ({ getUserInfo: { isAdmin, username, logo, userType } }) => {
        return <Topbar isAdmin={isAdmin} username={username} logo={logo} userType={userType} />;
      }
    )
  );