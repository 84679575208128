// DO NOT EDIT MANUALLY - metarpheus-generated
import * as t from 'io-ts';
// @ts-ignore
import { Option } from 'fp-ts/Option';
import { chain } from 'fp-ts/Either';
import { flow } from 'fp-ts/function';
// @ts-ignore
import { optionFromNullable } from 'io-ts-types/lib/optionFromNullable';

/* eslint-disable */
import { LocalDateTime as _LocalDateTime } from 'local-date';

interface Newtype<URI, A> {
  _URI: URI;
  _A: A;
}
interface Iso<S, A> {
  unwrap: (s: S) => A;
  wrap: (a: A) => S;
}
const unsafeCoerce = <A, B>(a: A): B => a as any;
type Carrier<N extends Newtype<any, any>> = N['_A'];
type AnyNewtype = Newtype<any, any>;
const fromNewtype =
  <N extends AnyNewtype>() =>
  <O>(type: t.Type<Carrier<N>, O>): t.Type<N, O> =>
    type as any;
const iso = <S extends AnyNewtype>(): Iso<S, Carrier<S>> => ({
  wrap: unsafeCoerce,
  unwrap: unsafeCoerce,
});

export const LocalDateTime = new t.Type<_LocalDateTime, string>(
  'LocalDateTime',
  (m): m is _LocalDateTime => m instanceof _LocalDateTime,
  (m, c) =>
    flow(
      (m, c: t.Context) => t.string.validate(m, c),
      chain<t.Errors, string, _LocalDateTime>((s) => {
        try {
          const d = new _LocalDateTime(s);
          return t.success(d);
        } catch (_) {
          return t.failure(s, c);
        }
      })
    )(m, c),
  (a) => a.toISOString()
);
export type LocalDateTime = t.TypeOf<typeof LocalDateTime>;

export const UUID = t.string;
export const Auth = t.string;
export const Json = t.dictionary(t.string, t.any);
export type Json = t.TypeOf<typeof Json>;
export const JsonWrapper = Json;
export type JsonWrapper = t.TypeOf<typeof JsonWrapper>;
export type Auth = t.TypeOf<typeof Auth>;
export type UUID = t.TypeOf<typeof UUID>;
export const UUIDWrapper = t.string;
export type UUIDWrapper = t.TypeOf<typeof UUIDWrapper>;
export const LocalDateTimeWrapper = LocalDateTime;
export type LocalDateTimeWrapper = t.TypeOf<typeof LocalDateTimeWrapper>;

export const VoidFromUnit = new t.Type<void, {}>(
  'VoidFromUnit',
  (_m): _m is void => true,
  () => t.success(undefined),
  () => ({})
);
export interface Acknowledged {
  acknowledged: boolean;
}

export const Acknowledged = t.type(
  {
    acknowledged: t.boolean,
  },
  'Acknowledged'
);

export type AuditEvent =
  | 'UserAccess'
  | 'Logout'
  | 'TransactionFlaggedUnsafe'
  | 'TransactionFlaggedSafe'
  | 'UserCreated'
  | 'UserDeleted'
  | 'GroupChanged'
  | 'GroupCreated'
  | 'GroupDeleted'
  | 'PermissionChanged'
  | 'StaticRuleSaved'
  | 'StaticRuleDeleted'
  | 'StaticRuleUpdated';

export const AuditEvent = t.keyof(
  {
    UserAccess: null,
    Logout: null,
    TransactionFlaggedUnsafe: null,
    TransactionFlaggedSafe: null,
    UserCreated: null,
    UserDeleted: null,
    GroupChanged: null,
    GroupCreated: null,
    GroupDeleted: null,
    PermissionChanged: null,
    StaticRuleSaved: null,
    StaticRuleDeleted: null,
    StaticRuleUpdated: null,
  },
  'AuditEvent'
);

export type UserType = 'Normal' | 'Undertrained' | 'NewUser';

export const UserType = t.keyof(
  {
    Normal: null,
    Undertrained: null,
    NewUser: null,
  },
  'UserType'
);

export type FraudulentType = 'Unknown' | 'Unsafe' | 'Safe' | 'All';

export const FraudulentType = t.keyof(
  {
    Unknown: null,
    Unsafe: null,
    Safe: null,
    All: null,
  },
  'FraudulentType'
);

export type Risk = 'Low' | 'Medium' | 'High' | 'All';

export const Risk = t.keyof(
  {
    Low: null,
    Medium: null,
    High: null,
    All: null,
  },
  'Risk'
);

export interface BobafettTransaction {
  id: UUID;
  scope: string;
  offsetDateTime: LocalDateTime;
  customerId: string;
  userType: UserType;
  fraudulentType: FraudulentType;
  score: number;
  hasMatchedAnyRule: boolean;
  columns: Json;
  risk: Risk;
  dbHasDetails: boolean;
}

export const BobafettTransaction = t.type(
  {
    id: UUID,
    scope: t.string,
    offsetDateTime: LocalDateTime,
    customerId: t.string,
    userType: UserType,
    fraudulentType: FraudulentType,
    score: t.number,
    hasMatchedAnyRule: t.boolean,
    columns: Json,
    risk: Risk,
    dbHasDetails: t.boolean,
  },
  'BobafettTransaction'
);

export type UserLoginType = 'Local' | 'Azure' | 'Importer';

export const UserLoginType = t.keyof(
  {
    Local: null,
    Azure: null,
    Importer: null,
  },
  'UserLoginType'
);

export type RuleType = 'Black' | 'White' | 'BlackBias' | 'WhiteBias';

export const RuleType = t.keyof(
  {
    Black: null,
    White: null,
    BlackBias: null,
    WhiteBias: null,
  },
  'RuleType'
);

export interface StaticRuleAudit {
  ruleName: string;
  query: string;
  active: boolean;
  ruleType: RuleType;
  scope: string;
}

export const StaticRuleAudit = t.type(
  {
    ruleName: t.string,
    query: t.string,
    active: t.boolean,
    ruleType: RuleType,
    scope: t.string,
  },
  'StaticRuleAudit'
);

export type AuditEventAdditionalInfo =
  | {
      transaction: BobafettTransaction;
      _type: 'TransactionFlagged';
    }
  | {
      username: string;
      email: string;
      group: string;
      loginType: UserLoginType;
      _type: 'UserCreated';
    }
  | {
      username: string;
      _type: 'UserDeleted';
    }
  | {
      username: string;
      oldGroup: string;
      newGroup: string;
      _type: 'GroupChanged';
    }
  | {
      username: string;
      groupName: string;
      permissions: Record<string, Array<string>>;
      _type: 'GroupCreated';
    }
  | {
      groupName: string;
      _type: 'GroupDeleted';
    }
  | {
      groupName: string;
      oldPermissions: Record<string, Array<string>>;
      newPermissions: Record<string, Array<string>>;
      _type: 'PermissionChanged';
    }
  | {
      staticRule: StaticRuleAudit;
      _type: 'StaticRuleSaved';
    }
  | {
      ruleName: string;
      _type: 'StaticRuleDeleted';
    }
  | {
      oldStaticRule: StaticRuleAudit;
      newStaticRule: StaticRuleAudit;
      _type: 'StaticRuleUpdated';
    };

export const AuditEventAdditionalInfo = t.union(
  [
    t.type(
      {
        transaction: BobafettTransaction,
        _type: t.literal('TransactionFlagged'),
      },
      'TransactionFlagged'
    ),
    t.type(
      {
        username: t.string,
        email: t.string,
        group: t.string,
        loginType: UserLoginType,
        _type: t.literal('UserCreated'),
      },
      'UserCreated'
    ),
    t.type(
      {
        username: t.string,
        _type: t.literal('UserDeleted'),
      },
      'UserDeleted'
    ),
    t.type(
      {
        username: t.string,
        oldGroup: t.string,
        newGroup: t.string,
        _type: t.literal('GroupChanged'),
      },
      'GroupChanged'
    ),
    t.type(
      {
        username: t.string,
        groupName: t.string,
        permissions: t.record(t.string, t.array(t.string)),
        _type: t.literal('GroupCreated'),
      },
      'GroupCreated'
    ),
    t.type(
      {
        groupName: t.string,
        _type: t.literal('GroupDeleted'),
      },
      'GroupDeleted'
    ),
    t.type(
      {
        groupName: t.string,
        oldPermissions: t.record(t.string, t.array(t.string)),
        newPermissions: t.record(t.string, t.array(t.string)),
        _type: t.literal('PermissionChanged'),
      },
      'PermissionChanged'
    ),
    t.type(
      {
        staticRule: StaticRuleAudit,
        _type: t.literal('StaticRuleSaved'),
      },
      'StaticRuleSaved'
    ),
    t.type(
      {
        ruleName: t.string,
        _type: t.literal('StaticRuleDeleted'),
      },
      'StaticRuleDeleted'
    ),
    t.type(
      {
        oldStaticRule: StaticRuleAudit,
        newStaticRule: StaticRuleAudit,
        _type: t.literal('StaticRuleUpdated'),
      },
      'StaticRuleUpdated'
    ),
  ],
  'AuditEventAdditionalInfo'
);

export interface CompanyId extends Newtype<{ readonly CompanyId: unique symbol }, number> {}

export const CompanyId = fromNewtype<CompanyId>()(t.Integer);
export const companyIdIso = iso<CompanyId>();

export interface AuditLog {
  company: CompanyId;
  username: string;
  timestamp: LocalDateTime;
  event: AuditEvent;
  additionalDetails: Option<AuditEventAdditionalInfo>;
}

export const AuditLog = t.type(
  {
    company: CompanyId,
    username: t.string,
    timestamp: LocalDateTime,
    event: AuditEvent,
    additionalDetails: optionFromNullable(AuditEventAdditionalInfo),
  },
  'AuditLog'
);

export interface TimeWindow {
  start: LocalDateTime;
  end: LocalDateTime;
}

export const TimeWindow = t.type(
  {
    start: LocalDateTime,
    end: LocalDateTime,
  },
  'TimeWindow'
);

export interface TemporalFlag {
  scope: string;
  period: TimeWindow;
}

export const TemporalFlag = t.type(
  {
    scope: t.string,
    period: TimeWindow,
  },
  'TemporalFlag'
);

export interface BobafettUser {
  user: string;
  properties: Record<string, string>;
  temporal: {
    Month?: Array<TemporalFlag>;
    Week?: Array<TemporalFlag>;
    Day?: Array<TemporalFlag>;
  };
}

export const BobafettUser = t.type(
  {
    user: t.string,
    properties: t.record(t.string, t.string),
    temporal: t.partial({
      Month: t.array(TemporalFlag),
      Week: t.array(TemporalFlag),
      Day: t.array(TemporalFlag),
    }),
  },
  'BobafettUser'
);

export interface BobafettUserInfo {
  user: string;
  properties: Record<string, string>;
}

export const BobafettUserInfo = t.type(
  {
    user: t.string,
    properties: t.record(t.string, t.string),
  },
  'BobafettUserInfo'
);

export type Graph = 'Line' | 'Histogram' | 'Radar' | 'Pie' | 'Text';

export const Graph = t.keyof(
  {
    Line: null,
    Histogram: null,
    Radar: null,
    Pie: null,
    Text: null,
  },
  'Graph'
);

export type StatisticDisplaySetting = 'Dashboard' | 'ScopePage' | 'Both';

export const StatisticDisplaySetting = t.keyof(
  {
    Dashboard: null,
    ScopePage: null,
    Both: null,
  },
  'StatisticDisplaySetting'
);

export interface Chart {
  graph: Graph;
  data: Record<string, number>;
  displayMode: StatisticDisplaySetting;
  unit: string;
  size: number;
  title: string;
}

export const Chart = t.type(
  {
    graph: Graph,
    data: t.record(t.string, t.number),
    displayMode: StatisticDisplaySetting,
    unit: t.string,
    size: t.Integer,
    title: t.string,
  },
  'Chart'
);

export interface ClientCredentials {
  clientId: string;
  clientSecret: string;
}

export const ClientCredentials = t.type(
  {
    clientId: t.string,
    clientSecret: t.string,
  },
  'ClientCredentials'
);

export interface PermissionId extends Newtype<{ readonly PermissionId: unique symbol }, number> {}

export const PermissionId = fromNewtype<PermissionId>()(t.Integer);
export const permissionIdIso = iso<PermissionId>();

export type OperationName = 'importTransaction' | 'admin' | 'bobafettRead' | 'bobafettWrite';

export const OperationName = t.keyof(
  {
    importTransaction: null,
    admin: null,
    bobafettRead: null,
    bobafettWrite: null,
  },
  'OperationName'
);

export interface CompanyPermission {
  id: PermissionId;
  operation: OperationName;
  scope: Option<string>;
  userGranted: boolean;
  groupGranted: boolean;
}

export const CompanyPermission = t.type(
  {
    id: PermissionId,
    operation: OperationName,
    scope: optionFromNullable(t.string),
    userGranted: t.boolean,
    groupGranted: t.boolean,
  },
  'CompanyPermission'
);

export interface ConceptLocalisation {
  singular: string;
  plural: string;
}

export const ConceptLocalisation = t.type(
  {
    singular: t.string,
    plural: t.string,
  },
  'ConceptLocalisation'
);

export interface KeyVal {
  key: string;
  value: number;
}

export const KeyVal = t.type(
  {
    key: t.string,
    value: t.number,
  },
  'KeyVal'
);

export interface CustomerProfileHistogramApiResponse {
  unit: Option<string>;
  data: Array<KeyVal>;
}

export const CustomerProfileHistogramApiResponse = t.type(
  {
    unit: optionFromNullable(t.string),
    data: t.array(KeyVal),
  },
  'CustomerProfileHistogramApiResponse'
);

export type EmailResetType = 'Mail' | 'Local';

export const EmailResetType = t.keyof(
  {
    Mail: null,
    Local: null,
  },
  'EmailResetType'
);

export interface FrontendConfiguration {
  resetPasswordType: EmailResetType;
  ron: boolean;
}

export const FrontendConfiguration = t.type(
  {
    resetPasswordType: EmailResetType,
    ron: t.boolean,
  },
  'FrontendConfiguration'
);

export type FrontendTransactionProperty =
  | {
      field: string;
      group: string;
      isColumn: boolean;
      _type: 'StringProperty';
    }
  | {
      field: string;
      group: string;
      isColumn: boolean;
      _type: 'BooleanProperty';
    }
  | {
      field: string;
      group: string;
      isColumn: boolean;
      format: string;
      _type: 'DateProperty';
    }
  | {
      field: string;
      group: string;
      isColumn: boolean;
      _type: 'NumericalProperty';
    };

export const FrontendTransactionProperty = t.union(
  [
    t.type(
      {
        field: t.string,
        group: t.string,
        isColumn: t.boolean,
        _type: t.literal('StringProperty'),
      },
      'StringProperty'
    ),
    t.type(
      {
        field: t.string,
        group: t.string,
        isColumn: t.boolean,
        _type: t.literal('BooleanProperty'),
      },
      'BooleanProperty'
    ),
    t.type(
      {
        field: t.string,
        group: t.string,
        isColumn: t.boolean,
        format: t.string,
        _type: t.literal('DateProperty'),
      },
      'DateProperty'
    ),
    t.type(
      {
        field: t.string,
        group: t.string,
        isColumn: t.boolean,
        _type: t.literal('NumericalProperty'),
      },
      'NumericalProperty'
    ),
  ],
  'FrontendTransactionProperty'
);

export interface FrontendTransactionConfiguration {
  scope: string;
  defaultDateFormat: string;
  transactionProperties: Array<FrontendTransactionProperty>;
  idField: string;
  generatedProperties: Record<string, Array<string>>;
}

export const FrontendTransactionConfiguration = t.type(
  {
    scope: t.string,
    defaultDateFormat: t.string,
    transactionProperties: t.array(FrontendTransactionProperty),
    idField: t.string,
    generatedProperties: t.record(t.string, t.array(t.string)),
  },
  'FrontendTransactionConfiguration'
);

export interface FrontendScopeConfiguration {
  scope: string;
  scopeGroup: string;
  statisticConfExist: boolean;
  configuration: FrontendTransactionConfiguration;
  translations: Record<string, string>;
  avgThresholds: number;
}

export const FrontendScopeConfiguration = t.type(
  {
    scope: t.string,
    scopeGroup: t.string,
    statisticConfExist: t.boolean,
    configuration: FrontendTransactionConfiguration,
    translations: t.record(t.string, t.string),
    avgThresholds: t.number,
  },
  'FrontendScopeConfiguration'
);

export interface FrontendUserViewConfiguration {
  userFields: Array<string>;
  translations: Record<string, string>;
}

export const FrontendUserViewConfiguration = t.type(
  {
    userFields: t.array(t.string),
    translations: t.record(t.string, t.string),
  },
  'FrontendUserViewConfiguration'
);

export interface GroupId extends Newtype<{ readonly GroupId: unique symbol }, number> {}

export const GroupId = fromNewtype<GroupId>()(t.Integer);
export const groupIdIso = iso<GroupId>();

export interface Group {
  id: GroupId;
  name: string;
  userCount: number;
}

export const Group = t.type(
  {
    id: GroupId,
    name: t.string,
    userCount: t.Integer,
  },
  'Group'
);

export type HttpError =
  | {
      message: string;
      _type: 'Forbidden';
    }
  | {
      message: string;
      _type: 'BadRequest';
    }
  | {
      message: string;
      _type: 'NotAuthorized';
    }
  | {
      message: string;
      _type: 'InternalServerError';
    }
  | {
      message: string;
      _type: 'NotFound';
    }
  | {
      error: string;
      error_description: Option<string>;
      error_uri: Option<string>;
      _type: 'OAuthTokenErrorResponse';
    }
  | {
      error: string;
      error_description: Option<string>;
      error_uri: Option<string>;
      _type: 'OAuthTokenErrorResponseUnauthorized';
    };

export const HttpError = t.union(
  [
    t.type(
      {
        message: t.string,
        _type: t.literal('Forbidden'),
      },
      'Forbidden'
    ),
    t.type(
      {
        message: t.string,
        _type: t.literal('BadRequest'),
      },
      'BadRequest'
    ),
    t.type(
      {
        message: t.string,
        _type: t.literal('NotAuthorized'),
      },
      'NotAuthorized'
    ),
    t.type(
      {
        message: t.string,
        _type: t.literal('InternalServerError'),
      },
      'InternalServerError'
    ),
    t.type(
      {
        message: t.string,
        _type: t.literal('NotFound'),
      },
      'NotFound'
    ),
    t.type(
      {
        error: t.string,
        error_description: optionFromNullable(t.string),
        error_uri: optionFromNullable(t.string),
        _type: t.literal('OAuthTokenErrorResponse'),
      },
      'OAuthTokenErrorResponse'
    ),
    t.type(
      {
        error: t.string,
        error_description: optionFromNullable(t.string),
        error_uri: optionFromNullable(t.string),
        _type: t.literal('OAuthTokenErrorResponseUnauthorized'),
      },
      'OAuthTokenErrorResponseUnauthorized'
    ),
  ],
  'HttpError'
);

export interface LoginResult {
  username: string;
  recoveryToken: boolean;
  token: string;
}

export const LoginResult = t.type(
  {
    username: t.string,
    recoveryToken: t.boolean,
    token: t.string,
  },
  'LoginResult'
);

export type OrderType = 'ASC' | 'DESC';

export const OrderType = t.keyof(
  {
    ASC: null,
    DESC: null,
  },
  'OrderType'
);

export interface OrderByField {
  key: string;
  orderType: OrderType;
}

export const OrderByField = t.type(
  {
    key: t.string,
    orderType: OrderType,
  },
  'OrderByField'
);

export type PeriodUnit = 'Month' | 'Week' | 'Day';

export const PeriodUnit = t.keyof(
  {
    Month: null,
    Week: null,
    Day: null,
  },
  'PeriodUnit'
);

export interface RuleId extends Newtype<{ readonly RuleId: unique symbol }, number> {}

export const RuleId = fromNewtype<RuleId>()(t.Integer);
export const ruleIdIso = iso<RuleId>();

export interface Rule {
  id: RuleId;
  scope: string;
  title: string;
  createdAt: LocalDateTime;
  updatedAt: LocalDateTime;
  luceneQuery: string;
  active: boolean;
  rType: RuleType;
}

export const Rule = t.type(
  {
    id: RuleId,
    scope: t.string,
    title: t.string,
    createdAt: LocalDateTime,
    updatedAt: LocalDateTime,
    luceneQuery: t.string,
    active: t.boolean,
    rType: RuleType,
  },
  'Rule'
);

export interface ScopeGroup {
  subjectOfAnalysis: string;
  localisations: Record<string, ConceptLocalisation>;
  hasUserView: boolean;
}

export const ScopeGroup = t.type(
  {
    subjectOfAnalysis: t.string,
    localisations: t.record(t.string, ConceptLocalisation),
    hasUserView: t.boolean,
  },
  'ScopeGroup'
);

export interface ScoreContributions {
  transactionId: UUID;
  score: number;
  contributions: Record<string, number>;
}

export const ScoreContributions = t.type(
  {
    transactionId: UUID,
    score: t.number,
    contributions: t.record(t.string, t.number),
  },
  'ScoreContributions'
);

export interface TransactionsQueryResult<T> {
  txns: Array<T>;
  count: Option<number>;
}

export const TransactionsQueryResult = <T extends t.Mixed>(T: T) =>
  t.type(
    {
      txns: t.array(T),
      count: optionFromNullable(t.Integer),
    },
    'TransactionsQueryResult'
  );

export interface UserId extends Newtype<{ readonly UserId: unique symbol }, number> {}

export const UserId = fromNewtype<UserId>()(t.Integer);
export const userIdIso = iso<UserId>();

export interface User {
  userId: UserId;
  name: string;
  groupId: Option<GroupId>;
  email: string;
  userType: UserLoginType;
  company: CompanyId;
  isAdmin: boolean;
}

export const User = t.type(
  {
    userId: UserId,
    name: t.string,
    groupId: optionFromNullable(GroupId),
    email: t.string,
    userType: UserLoginType,
    company: CompanyId,
    isAdmin: t.boolean,
  },
  'User'
);

export interface UserScope {
  scope: string;
  subjectOfAnalysis: string;
  writable: boolean;
  localisations: Record<string, ConceptLocalisation>;
}

export const UserScope = t.type(
  {
    scope: t.string,
    subjectOfAnalysis: t.string,
    writable: t.boolean,
    localisations: t.record(t.string, ConceptLocalisation),
  },
  'UserScope'
);

export interface UserInfo {
  username: string;
  logo: Option<string>;
  scopes: Array<UserScope>;
  userType: UserLoginType;
  isAdmin: boolean;
  scopeGroups: Array<ScopeGroup>;
}

export const UserInfo = t.type(
  {
    username: t.string,
    logo: optionFromNullable(t.string),
    scopes: t.array(UserScope),
    userType: UserLoginType,
    isAdmin: t.boolean,
    scopeGroups: t.array(ScopeGroup),
  },
  'UserInfo'
);

export interface UserTemporalFlags {
  user: string;
  flags: {
    Month?: Array<TemporalFlag>;
    Week?: Array<TemporalFlag>;
    Day?: Array<TemporalFlag>;
  };
}

export const UserTemporalFlags = t.type(
  {
    user: t.string,
    flags: t.partial({
      Month: t.array(TemporalFlag),
      Week: t.array(TemporalFlag),
      Day: t.array(TemporalFlag),
    }),
  },
  'UserTemporalFlags'
);
