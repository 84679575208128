import {
  doSetTransactionToSafe,
  doSetTransactionToUnsafe,
  doSetTransactionsToSafe,
  doSetTransactionsToUnsafe,
} from '#commands';
import { config } from '#config';
import { BobafettTransaction } from '#metarpheus/model-ts';
import { ActionsProviderTypes } from '#models/providers';
import { taskify, map } from 'fp-ts/TaskEither';
import { constVoid, flow } from 'fp-ts/function';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

export const ActionsProvider = React.createContext<ActionsProviderTypes>({
  setTransactionAsUnsafe: taskify<unknown, void>(constVoid),
  setTransactionsAsUnsafe: taskify<unknown, number>(constVoid),
  gotoBobafettTransactionDetail: constVoid,
  gotoBobafettTransactionDetailInNewTab: constVoid,
  goToBobafettUserDetail: constVoid,
  goToBobafettUserDetailInNewTab: constVoid,
  lock: false,
  setBatchData: constVoid,
  batchData: [],
  setTransactionAsSafe: taskify<unknown, void>(constVoid),
  setTransactionsAsSafe: taskify<unknown, number>(constVoid),
});

export const ActionsContextProvider = (props: { children: any }) => {
  const history = useHistory();
  const [lock, setLock] = React.useState(false);
  const children = React.useMemo(() => props.children, [props]);

  const scope = window.sessionStorage.getItem('activeScope') || 'no scope';

  const setTransactionAsUnsafe = flow(
    (transactionId: string, _scope?: string) => {
      setLock(true);
      return { transactionId, scope: _scope || scope };
    },
    (params: { transactionId: string; scope: string }) => doSetTransactionToUnsafe(params),
    (res) => {
      setLock(false);
      return res;
    },
    map(_ => { })
  );

  const setTransactionAsSafe = flow(
    (transactionId: string, _scope?: string) => {
      setLock(true);
      return { transactionId, scope: _scope || scope };
    },
    (params: { transactionId: string; scope: string }) => doSetTransactionToSafe(params),

    (res) => {
      setLock(false);
      return res;
    },
    map(_ => { })
  );

  const setTransactionsAsUnsafe = flow(
    (transactionIds: string[], _scope?: string) => {
      setLock(true);
      return { transactionIds, scope: _scope || scope };
    },
    (params: { transactionIds: string[]; scope: string }) => doSetTransactionsToUnsafe(params),
    (res) => {
      setLock(false);
      return res;
    }
  );

  const setTransactionsAsSafe = flow(
    (transactionIds: string[], _scope?: string) => {
      setLock(true);
      return { transactionIds, scope: _scope || scope };
    },
    (params: { transactionIds: string[]; scope: string }) => doSetTransactionsToSafe(params),
    (res) => {
      setLock(false);
      return res;
    }
  );
  const [batchData, setBatchData] = React.useState<BobafettTransaction[]>([]);

  return (
    <ActionsProvider.Provider
      value={{
        setTransactionAsUnsafe,
        setTransactionAsSafe,
        setTransactionsAsUnsafe,
        setTransactionsAsSafe,
        lock,
        batchData,
        setBatchData,
        gotoBobafettTransactionDetail: (id: string, scope: string) => {
          history.push(generatePath(config.routesPath.DETAIL, { scope, id }));
        },
        gotoBobafettTransactionDetailInNewTab: (id: string, scope: string) => {
          window.open(generatePath(config.routesPath.DETAIL, { scope, id }), '_blank');
        },
        goToBobafettUserDetail: (customerId: string, scopeGroup: string) => {
          history.push(
            generatePath(config.routesPath.USER_VIEW_DETAIL, { customerId, scopeGroup })
          );
        },
        goToBobafettUserDetailInNewTab: (customerId: string, scopeGroup: string) => {
          window.open(
            generatePath(config.routesPath.USER_VIEW_DETAIL, { customerId, scopeGroup }),
            '_blank'
          );
        },
      }}
    >
      {children}
    </ActionsProvider.Provider>
  );
};
