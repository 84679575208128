import { setActiveScope } from '#commands';
import { config } from '#config';
import { ScopeGroup, UserScope } from '#metarpheus/model-ts';
import ErrorPage from '#pages/ErrorPage';
import IntlProvider from '#providers/IntlProvider';
import ScopedIntlProvider from '#providers/ScopedIntlProvider';
import UserIntlProvider from '#providers/UserIntlProvider';
import { useCheckScreenSize } from '#util/customhooks';
import { runTaskEither } from '#util/fpTs';
import { getLocalization, useBrowserSupportedLang } from '#util/intl';
import { filter, findFirst } from 'fp-ts/Array';
import { flatten, fromNullable, getOrElse, map } from 'fp-ts/Option';
import { pop } from 'fp-ts/Record';
import { constVoid, pipe } from 'fp-ts/function';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import ScreenIsTooSmall from '@banksealer/shared/commons/ScreenIsTooSmall';

const { loadLocale } = require('./loadLocale');

type Props = {
  component: JSX.Element;
  routeProps: RouteComponentProps;
  providerType: 'public' | 'private';
  scopeGroups?: ScopeGroup[];
  scopes?: UserScope[];
};
export default (props: Props) => {
  const lang = useBrowserSupportedLang();
  const {
    match: { params },
  } = props.routeProps;

  const { isExact: isUserView } = useRouteMatch([
    config.routesPath.USER_VIEW_DETAIL,
    config.routesPath.USER_VIEW_LIST,
  ]) || {
    isExact: false,
  };

  const { scope, scopeGroup: scopeGroupParm } = params as { scope?: string; scopeGroup?: string };

  const activeScopeGroupLocalisations = React.useMemo(
    () =>
      pipe(
        fromNullable(props.scopes),
        getOrElse<UserScope[]>(() => []),
        findFirst((s) => s.scope == scope),
        map((_) => _.subjectOfAnalysis),
        map((scopeSubjectOfAnalysis) =>
          pipe(
            props.scopeGroups,
            fromNullable,
            getOrElse<ScopeGroup[]>(() => []),
            filter((s) => s.localisations != undefined),
            findFirst((_) => _.subjectOfAnalysis === scopeSubjectOfAnalysis),
            map(_ => _.localisations),
            map(pop(lang)),
            flatten,
            map(([localication]) => localication),
          )
        ),
        flatten,
        map(_ => ({
          singular: _.singular,
          plural: _.plural,
        })
        ),
        getOrElse(() => ({
          singular: " ",
          plural: " ",
        })),
        (({ singular, plural }) => (
          {
            'Scope.Subject.singular': singular,
            'Scope.Subject.plural': plural,
          }
        ))
      ),
    [props, lang]
  );
  const translations = React.useMemo(() => {
    return {
      ...(props.scopes ? getLocalization(props.scopes, lang, 'subjectOfAnalysis', 'User') : {}),
      ...(props.scopes ? getLocalization(props.scopes, lang, 'scope', 'Scope') : {}),
      ...(props.scopeGroups
        ? getLocalization(props.scopeGroups, lang, 'subjectOfAnalysis', 'User')
        : {}),
      ...activeScopeGroupLocalisations,
    };
  }, [props.scopeGroups, props.scopes]);

  const isMinSize = useCheckScreenSize();

  const component = isUserView && !props.scopeGroups ? <ErrorPage status={404} /> : props.component;

  const Provider = (() => {
    if (isUserView) {
      return UserIntlProvider;
    } else if (scope) {
      return ScopedIntlProvider;
    } else {
      return IntlProvider;
    }
  })();

  React.useEffect(() => {
    if (scope) {
      runTaskEither(setActiveScope(scope as string))(constVoid, constVoid);
    }
  }, [scope]);

  return (
    <Provider
      loadLocale={loadLocale}
      locale={lang}
      additionalTransalations={translations}
      scope={scope || ''}
      scopeGroup={scopeGroupParm || ''}
    >
      {isMinSize ? <ScreenIsTooSmall /> : component}
    </Provider>
  );
};
