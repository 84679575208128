import { IntlProviderProps } from '#models/providers';
import React from 'react';
import { IntlProvider as IntlIntlProvider } from 'react-intl';

export default (props: IntlProviderProps) => {
  const intlData = React.useMemo(() => props.loadLocale(props.locale), []);
  return intlData ? (
    <IntlIntlProvider
      {...intlData}
      messages={{ ...intlData.messages, ...props.additionalTransalations }}
    >
      {' '}
      {props.children}
    </IntlIntlProvider>
  ) : null;
};
