import { IntlData } from '#models';
import { mapTranslationsKeys } from '#util/intl';
import { pipe } from 'fp-ts/function';
import React from 'react';
import { IntlProvider } from 'react-intl';

type Props<T> = {
  locale: string;
  intlData: IntlData;
  children: JSX.Element;
  translations: Record<string, string>;
  queryResult: T;
};

export default <T,>(props: Props<T>) => {
  const { locales = [], locale = props.locale, messages = {} } = props.intlData || {};
  const children = React.cloneElement(props.children, {
    queryResult: props.queryResult,
  });
  return pipe(
    mapTranslationsKeys(locale, locales, messages, props.translations),
    (mappedTranslations) => {
      return <IntlProvider {...mappedTranslations}>{children}</IntlProvider>;
    }
  );
};
