import { IntlData } from '#models';
import dayjs from 'dayjs';
import dayjs_it from 'dayjs/locale/it';
import dayjs_en from 'dayjs/locale/it';
import sharedEn from '@banksealer/shared/locales/en.json';
import sharedIt from '@banksealer/shared/locales/it.json';

/*

In this `loadLocale` function we import the required locale data for our app.
This function ensures only the reimport { addLocaleDataAndResolve } from '../util/intl';
quired files are loaded based on the locale we want to serve to the user.

The stesp to add a new locale (language) are:
- create a new file in `locales`, such as `myLang.json`. It should respect the format of the other locale files
- add the new `case` in the function below.
The documentation for `react-intl/locale-data` packages can be found at https://github.com/yahoo/react-intl/wiki#loading-locale-data

*/

export const loadLocale = (locale: string): IntlData => {
  switch (locale) {
    case 'it':
      dayjs.locale('it', dayjs_it);
      const it = require('../locales/it');
      return { ...it, messages: { ...it.messages, ...sharedIt.messages } };
    case 'en':
    default:
      dayjs.locale('en', dayjs_en);
      const en = require('../locales/en');
      return { ...en, messages: { ...en.messages, ...sharedEn.messages } };
  }
};
