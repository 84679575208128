import { config } from '#config';
import { ConceptLocalisation, UserScope } from '#metarpheus/model-ts';
import { ScopeGroup } from '#metarpheus/model-ts';
import { AxiosError } from 'axios';
import { filterMap, findFirst, reduce } from 'fp-ts/Array';
import { fold, none, some } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
// @ts-ignore
import isoConv from 'iso-language-converter';
import _first from 'lodash/first';
import _get from 'lodash/get';

export const getErrorMessage = (error: AxiosError | any) => {
  switch (error.response && error.response.status) {
    case 401:
      return 'Error.401';
    case 403:
      return 'Error.403';
    case 400: {
      return detectValidationServerError(error.response.data.message);
    }
    default:
      return 'Error.generic';
  }
};

export const detectValidationServerError = (serverValidationMessage: string) => {
  switch (serverValidationMessage) {
    case 'OLD':
      return 'Error.passwordIsSameAsOld';
    default:
      return 'Error.generic';
  }
};
export const useBrowserSupportedLang = (): string => {
  return pipe(
    config.supportedLangs.split(','),
    findFirst((e) => e === navigator.language.split('-').shift()),
    fold(
      () => config.defaultLanguage,
      (lang) => isoConv(lang, { to: 1 })
    )
  );
};

export const mapTranslationsKeys = (
  locale: string,
  locales: string[],
  messages: Record<string, string>,
  translations: Record<string, string>
) => {
  return {
    locales,
    messages: { ...messages, ...translations },
    locale,
  };
};

export const getLocalization = <T extends ScopeGroup | UserScope>(
  scopes: T[],
  lang: string,
  key: keyof T,
  type: string
) => {
  return pipe(
    scopes,
    filterMap((s) =>
      s.localisations != undefined
        ? some({
            scope: s[key],
            localisations: _get(
              s.localisations,
              lang,
              _first(Object.values(s.localisations)) as ConceptLocalisation
            ),
          })
        : none
    ),
    reduce(
      {} as Record<string, string>,
      (acc, l) => (
        (acc[`${type}.${l.scope}.singular`] = l.localisations.singular),
        (acc[`${type}.${l.scope}.plural`] = l.localisations.plural),
        acc
      )
    )
  );
};
