import Sidebar from '#commons/Sidebar';
import Topbar from '#commons/Topbar';
import Layout from '@banksealer/shared/commons/Layout';
import './layout.scss';

export default ({ children }: any) => {
  return (
    <Layout
      topbar={<Topbar />}
      sidebar={<Sidebar />}
      children={children}
      useMarginContainer={false}
    />
  );
};
