import * as API from '#API';
import { AuditEvent, GroupId, LocalDateTime, UserId } from '#metarpheus/model-ts';
import { available, compose, param, product, queryShallow, refetch } from 'avenger';
import { getToken } from './queries';

export const getAudits = compose(
  product({
    event: param<AuditEvent | undefined>(),
    from: param<LocalDateTime | undefined>(),
    to: param<LocalDateTime | undefined>(),
  }),
  queryShallow(({ event, from, to }) => API.getAuditLogs(getToken(), event, from, to), available)
);

export const getUsers = compose(
  product({
    groupId: param<GroupId | undefined>(),
  }),
  queryShallow(({ groupId }) => API.getUsers(getToken(), groupId), available)
);

export const getClients = queryShallow(() => API.getClients(getToken()), available);

export const getGroups = queryShallow(() => API.getGroups(getToken()), available);

export const getPermissions = compose(
  product({
    groupId: param<GroupId | undefined>(),
    userId: param<UserId | undefined>(),
  }),
  queryShallow(({ userId, groupId }) => API.getPermissions(getToken(), userId, groupId), refetch)
);

export const getJsonSchema = compose(
  product({
    scope: param<string>(),
  }),
  queryShallow(({ scope }) => API.getJsonSchema(scope, getToken()), refetch)
);
