import { config } from '#config';
import getRoutes from '#metarpheus/api';
import { OrderByField, Risk, RuleType } from '#metarpheus/model-ts';
import { ScopeSearchRequest } from '#models';
import { FraudulentType, RuleId, ruleIdIso } from '../metarpheus/model-ts';

const { apiEndpoint, timeout } = config;
const Routes = getRoutes({ apiEndpoint, timeout });

export interface Credentials {
  username: string;
  password: string;
  token: string | undefined;
}

export const doLogin = ({ username, password, token }: Credentials) =>
  Routes.authController_login({ username, password, token });

export const doResetPassword = (email: string) =>
  Routes.authController_registerForRecoveryFromEmail({ email });

export const doChangePassword = (newPassword: string, token: string) =>
  Routes.authController_recoverLogin({ newPassword, token });

export const doLogout = (token: string) => Routes.authController_logout({ token });

export const isSessionValid = (token: string) => Routes.authController_isSessionValid({ token });

export const getUserInfo = (token: string) => Routes.authController_getUserInfo({ token });

export const getScopeConfig = (token: string, scope: string, lang: string) =>
  Routes.confController_getScopeConfiguration({ token, scope, lang });

export const getUserViewConfig = (token: string, lang: string, scopeGroup: string) =>
  Routes.confController_getUserViewConfiguration({ token, lang, scopeGroup });

export const getScopeTransactions = ({
  token,
  scope,
  limit = 200,
  offset = 0,
  userId,
  risk,
  groupByField,
  fraudulentType,
  orderBy = [],
}: {
  token: string;
  scope: string;
  limit?: number;
  offset?: number;
  risk: Risk;
  groupByField?: string;
  fraudulentType: FraudulentType;
  orderBy?: OrderByField[];
  userId?: string;
}) =>
  Routes.transactionController_getTransactions({
    token,
    scope,
    limit,
    offset,
    userId,
    risk,
    groupByField,
    fradulentType: fraudulentType,
    orderBy,
  });

export const doSearch = ({
  scope,
  query,
  limit,
  offset,
  groupByField = undefined,
  orderBy = [],
  token,
}: ScopeSearchRequest) =>
  Routes.transactionController_searchTransactions({
    scope,
    query,
    limit,
    offset,
    groupByField,
    orderBy,
    token,
  });

export const getScopeTransaction = (token: string, scope: string, id: string) =>
  Routes.transactionController_getTransaction({ token, scope, id });

export const getTransactionContribution = (token: string, id: string, scope: string) =>
  Routes.transactionController_getScoreContributions({ token, id, scope });

export const getTimeWindow = (token: string, scope: string) =>
  Routes.transactionController_getTimeWindow({ token, scope });

export const setTransactionAsUnsafe = (token: string, transactionId: string, scope: string) =>
  Routes.transactionController_setAsUnsafe({ token, transactionId, scope });

export const setTransactionAsSafe = (token: string, transactionId: string, scope: string) =>
  Routes.transactionController_setAsSafe({ token, transactionId, scope });

export const setTransactionsAsUnsafe = (token: string, transactionIds: string[], scope: string) =>
  Routes.transactionController_setAsUnsafeBatch({ token, transactionIds, scope });

export const setTransactionsAsSafe = (token: string, transactionIds: string[], scope: string) =>
  Routes.transactionController_setAsSafeBatch({ token, transactionIds, scope });

export const createStaticRule = (
  scope: string,
  title: string,
  luceneQuery: string,
  active: boolean,
  rType: RuleType,
  token: string
) =>
  Routes.staticRuleController_saveRule({
    scope,
    title,
    luceneQuery,
    active,
    rType,
    token,
  });

export const updateStaticRule = (
  id: RuleId,
  scope: string,
  title: string,
  luceneQuery: string,
  active: boolean,
  rType: RuleType,
  token: string
) =>
  Routes.staticRuleController_updateRule({
    id: ruleIdIso.unwrap(id),
    scope,
    title,
    luceneQuery,
    active,
    rType,
    token,
  });

export const getStaticRules = (token: string, scope: string) =>
  Routes.staticRuleController_getAll({ scope, token });

export const deleteStaticRule = (id: number, scope: string, token: string) =>
  Routes.staticRuleController_deleteRule({ id, scope, token });

export const getStatistics = (token: string, scope: string, lang: string) =>
  Routes.statisticController_getStatistics({ scope, lang, token });

export const getHistogramFeature = (
  token: string,
  scope: string,
  customerId: string,
  feature: string
) =>
  Routes.histogramController_getCustomerProfileHistogram({
    customerId,
    scope,
    feature,
    token,
  });

export const getTemporalFlags = (token: string, subject: string, userId: string) =>
  Routes.userController_getTemporalFlags({ userId, subject, token });

export const getTemporalTimeWindow = (token: string) =>
  Routes.userController_getTemporalTimeWindow({ token });

export const getBobafettUserInfo = (token: string, subject: string, userId: string) =>
  Routes.userController_getUserInfo({ userId, subject, token });

export const getAllUsers = (
  token: string,
  onlyFlagged: boolean,
  offset: number,
  limit: number,
  subject: string,
  userSearchPattern?: string
) =>
  Routes.userController_getAll({
    token,
    onlyFlagged,
    offset,
    limit,
    userSearchPattern,
    subject,
  });

export const azureLogin = (jwt: string) => Routes.authController_azureSSO({ jwt });

export const getFrontendConf = Routes.confController_getFrontendConfig;

export const getFeatures = (token: string, scope: string) =>
  Routes.histogramController_getFeatures({ token, scope });
