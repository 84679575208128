import * as QR from 'avenger/lib/QueryResult';
import ErrorPage from '#pages/ErrorPage';
import IntlProvider from '#providers/IntlProvider';
import { getUserInfo, isSessionValid } from '#queries';
import errorDetect from '#util/errorDetect';
import { getLocalization, useBrowserSupportedLang } from '#util/intl';
import { useQueries } from 'avenger/lib/react';
import { AxiosError } from 'axios';
import { pipe } from 'fp-ts/function';
import React from 'react';
import { useIntl } from 'react-intl';
import { Route, RouteComponentProps } from 'react-router-dom';
import Pending from '@banksealer/shared/commons/Pending';
import SessionExpired from './setup/sessionExpired';

const { loadLocale } = require('./setup/loadLocale');

const ErrorPageWithIntlCmp = ({ errorResponse }: { errorResponse: unknown }) => {
  const intl = useIntl();
  React.useEffect(() => {
    const { response: { status: errorResponseStatusCode = 500 } = {} } =
      errorResponse as AxiosError;
    errorDetect(errorResponseStatusCode as number, intl);
  }, [errorResponse, intl]);
  return <ErrorPage />;
};
const ErrorPageWithIntl = ({ errorResponse }: { errorResponse: unknown }) => {
  const lang = useBrowserSupportedLang();
  return (
    <IntlProvider loadLocale={loadLocale} locale={lang}>
      <ErrorPageWithIntlCmp errorResponse={errorResponse} />
    </IntlProvider>
  );
};

export default ({ component: Component, restricted, disableFetchIntl = false, ...rest }: any) => {
  return pipe(
    useQueries({ isSessionValid, getUserInfo }),
    QR.fold(
      () => <Pending />,
      (e) => <ErrorPageWithIntl errorResponse={e} />,
      ({ isSessionValid, getUserInfo }) => {
        const lang = useBrowserSupportedLang();
        const translations = {
          ...getLocalization(getUserInfo.scopes, lang, 'scope', 'Scope'),
          ...getLocalization(getUserInfo.scopeGroups, lang, 'subjectOfAnalysis', 'User'),
        };

        const _Component = (props: RouteComponentProps) => {
          if (!isSessionValid) {
            return <SessionExpired />;
          }
          if (!getUserInfo.isAdmin) {
            return <ErrorPage status={403} />;
          }
          return <Component {...props} />;
        };
        return (
          <Route
            {...rest}
            render={(props) => {
              return (
                <IntlProvider
                  loadLocale={loadLocale}
                  additionalTransalations={translations}
                  locale={lang}
                >
                  <_Component {...props} />
                </IntlProvider>
              );
            }}
          />
        );
      }
    )
  );
};
