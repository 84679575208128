import { ReactComponent as Logo } from '#assets/logo.svg';
import FlexView from 'react-flexview';
import { useIntl } from 'react-intl';

export default () => {
  const intl = useIntl();
  return (
    <FlexView height="100%" width="100%">
      <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center text-center">
        <Logo className="my-4" />
        <p
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: 'ScreenToSmall' }),
          }}
        />
      </div>
    </FlexView>
  );
};
