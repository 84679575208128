import { config } from '#config';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

export default () => {
  const intl = useIntl();
  toast.error(intl.formatMessage({ id: 'SessionExpired' }));
  return <Redirect to={config.routesPath.LOGIN} />;
};
