import cxDedupe from 'classnames/dedupe';
import React from 'react';
import './header.scss';

type Props = {
  children: JSX.Element;
  classNameRow?: Object;
  className?: Object;
  useNav?: boolean;
};

export default ({ children, useNav = false, classNameRow = '', className = '' }: Props) => {
  const classNameEl = React.useMemo(() => {
    const baseClassNameEl = ['bs-header', 'container-fluid', 'py-1'];
    return cxDedupe(
      Object.assign(
        baseClassNameEl.reduce((p, v) => ({ ...p, ...{ [v]: true } }), {}),
        className
      )
    );
  }, [className]);
  const classNameContainer = React.useMemo(() => {
    const baseClassNameContainer = ['row', 'h-100', 'content-sidebar', 'mx-1'];
    return cxDedupe(
      Object.assign(
        baseClassNameContainer.reduce((p, v) => ({ ...p, ...{ [v]: true } }), {}),
        classNameRow
      )
    );
  }, [classNameRow]);

  const El = ({ className, children }: { className: string; children: JSX.Element }) =>
    React.createElement(useNav ? 'nav' : 'div', { className }, children);
  return (
    <El className={classNameEl}>
      <div className={classNameContainer}>{children}</div>
    </El>
  );
};
