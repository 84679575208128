import { config } from '#config';
import getRoutes from '#metarpheus/api';
import {
  AuditEvent,
  GroupId,
  LocalDateTime,
  PermissionId,
  UserId,
  UserLoginType,
} from '#metarpheus/model-ts';

const { apiEndpoint, timeout } = config;
const Routes = getRoutes({ apiEndpoint, timeout });

export const getAuditLogs = (
  token: string,
  event?: AuditEvent,
  from?: LocalDateTime,
  to?: LocalDateTime
) =>
  Routes.adminController_getAuditLogs({
    token,
    event,
    from,
    to,
  });

export const getUsers = (token: string, groupId?: GroupId) =>
  Routes.adminController_getUsers({
    token,
    groupId,
  });

export const getGroups = (token: string) => Routes.adminController_getGroups({ token });

export const getPermissions = (token: string, userId?: UserId, groupId?: GroupId) =>
  Routes.adminController_getCompanyPermissions({ token, userId, groupId });

export const createUser = (
  username: string,
  email: string,
  defaultPassword: string,
  userType: UserLoginType,
  permissions: Array<PermissionId>,
  token: string,
  groupId?: GroupId
) =>
  Routes.adminController_createUser({
    username,
    email,
    groupId,
    defaultPassword,
    userType,
    permissions,
    token,
  });

export const deleteUser = (userId: UserId, token: string) =>
  Routes.adminController_deleteUser({ userId, token });

export const createGroup = (name: string, permissions: Array<PermissionId>, token: string) =>
  Routes.adminController_createGroup({ name, permissions, token });

export const editGroup = (groupId: GroupId, permissions: Array<PermissionId>, token: string) =>
  Routes.adminController_changeGroupPermissions({
    groupId,
    permissions,
    token,
  });

export const deleteGroup = (groupId: GroupId, token: string) =>
  Routes.adminController_deleteGroup({ groupId, token });

export const editUser = (
  userId: UserId,
  userType: UserLoginType,
  permissions: Array<PermissionId>,
  token: string,
  newGroupId?: GroupId
) => Routes.adminController_editUser({ userId, newGroupId, userType, permissions, token });

export const resetPassword = (userId: UserId, newPassword: string, token: string) =>
  Routes.adminController_resetPassword({ token, userId, newPassword });

export const registerClient = (
  userType: UserLoginType,
  permissions: Array<PermissionId>,
  token: string
) => Routes.adminController_registerClient({ token, userType, permissions });

export const unregisterClient = (clientId: string, token: string) =>
  Routes.adminController_unregisterClient({ token, clientId });

export const getClients = (token: string) => Routes.adminController_getClients({ token });

export const changeClientPermissions = (
  clientId: UserId,
  permissions: Array<PermissionId>,
  token: string
) => Routes.adminController_changeClientPermissions({ token, permissions, clientId });

export const getJsonSchema = (scope: string, token: string) =>
  Routes.adminController_getJsonSchema({ scope, token });
