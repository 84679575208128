const { string: t_string, type: ttype, array: t_array } = require('io-ts');
const { NonEmptyString } = require('io-ts-types/lib/NonEmptyString');
const { pipe } = require('fp-ts/function');
const { fold } = require('fp-ts/Either');
const { failure } = require('io-ts/lib/PathReporter');
const defaultConfig = require('./defaultConfig.json');
const path = require('path');

require('dotenv').config({
  path: path.resolve(process.cwd(), `.env.${process.env.NODE_ENV}`),
});

const Config = ttype(
  {
    REACT_APP_TITLE: NonEmptyString,
    REACT_APP_DESCRIPTION: NonEmptyString,
    REACT_APP_API_ENDPOINT: t_string,
    REACT_APP_TIMEOUT: t_string,
    REACT_APP_SUPPORTED_LANGS: t_string,
    REACT_APP_DEFAULT_LANGUAGE: t_string,
    REACT_APP_MIN_WIDTH: t_string,
    REACT_APP_MIN_HEIGHT: t_string,
    REACT_APP_WHITE_LABEL: t_string,
    REACT_APP_SCOPE_SORTABLE_FIELDS: t_array(t_string),
    REACT_APP_DEFAULT_CSV_FIELDS: t_array(t_string),
    REACT_APP_PASSWORD_CRITERIA: ttype({
      minLength: t_string,
      haveNumbers: t_string,
      upperChars: t_string,
      lowerChars: t_string,
      specialChars: t_string,
    }),
    REACT_APP_ROUTES_PATH: ttype({
      LOGIN: t_string,
      AZURE_REDIRECT: t_string,
      FORGOT_PASSWORD: t_string,
      CHANGE_PASSWORD: t_string,
      LOGIN_SUCCESS: t_string,
      SCOPE: t_string,
      SEARCH: t_string,
      DETAIL: t_string,
      DASHBOARD: t_string,
      STATIC_RULES: t_string,
      BACKOFFICE_BASE: t_string,
      BACKOFFICE_AUDITS: t_string,
      BACKOFFICE_GROUPS: t_string,
      BACKOFFICE_USERS: t_string,
      BACKOFFICE_CLIENTS: t_string,
      HEALTH: t_string,
      USER_VIEW_LIST: t_string,
      USER_VIEW_DETAIL: t_string,
    }),
  },
  'Config'
);

module.exports = {
  config: pipe(
    process.env,
    (e) => Object.assign({}, e, defaultConfig),
    Config.decode,
    fold(
      (errors) => {
        throw new Error(['Invalid config provided:', failure(errors)].join('\n'));
      },
      (env) => ({
        title: env.REACT_APP_TITLE,
        description: env.REACT_APP_DESCRIPTION,
        apiEndpoint: env.REACT_APP_API_ENDPOINT,
        timeout: parseInt(env.REACT_APP_TIMEOUT),
        supportedLangs: env.REACT_APP_SUPPORTED_LANGS,
        defaultLanguage: env.REACT_APP_DEFAULT_LANGUAGE,
        minWidth: parseInt(env.REACT_APP_MIN_WIDTH),
        minHeight: parseInt(env.REACT_APP_MIN_HEIGHT),
        whiteLabel: parseInt(env.REACT_APP_WHITE_LABEL) === 1,
        passwordCriteria: env.REACT_APP_PASSWORD_CRITERIA,
        routesPath: env.REACT_APP_ROUTES_PATH,
        scopeSortableFields: env.REACT_APP_SCOPE_SORTABLE_FIELDS,
        defaultCsvFields: env.REACT_APP_DEFAULT_CSV_FIELDS,
      })
    )
  ),
};