import './layout.scss';

type Props = {
  children: JSX.Element;
  topbar: JSX.Element;
  useMarginContainer?: boolean;
  sidebar?: JSX.Element;
};
export default ({ children, topbar, sidebar, useMarginContainer = true }: Props) => {
  return (
    <div className="container-fluid user-select-none p-0 h-100">
      <div className="row">
        <div className="col-12 px-1">{topbar}</div>
      </div>
      <div className={`row ${useMarginContainer ? 'mt-3' : ''}`}>
        <div className="col-12">
          <div className="d-flex container-fluid justify-content-between m-0 p-0">
            {sidebar}
            <main className="w-100">{children}</main>
          </div>
        </div>
      </div>
    </div>
  );
};
