import { Route } from 'react-router-dom';
import RouteLoader from './RouteLoader';

export default ({ component: Component, restricted, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <RouteLoader
          component={<Component {...props} />}
          providerType={'public'}
          routeProps={props}
        />
      )}
    />
  );
};
