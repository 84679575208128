import { Credentials } from '#API';
import { AgGridSortModel } from '#models/agGrid';
import { FormOnSubmitProps } from '#models/staticRule';
import { getActiveScope, getStaticRules, getToken } from '#queries';
import { command } from 'avenger';
import { of } from 'fp-ts/IO';
import { TaskEither, chain, fromIO, map } from 'fp-ts/TaskEither';
import { flow, pipe } from 'fp-ts/function';
import * as Api from '../API';
import { Rule, RuleId, ruleIdIso } from '../metarpheus/model-ts';

export const chainFromToken = <A extends unknown, B extends string, C>(
  refinement: (t: B) => TaskEither<A, C>
) => pipe(getToken() as B, refinement);

export const doLogin = command(
  flow(
    (credentials: Credentials) => Api.doLogin(credentials),
    map(({ token, ...oth }) => {
      window.localStorage.setItem('AUTH_TOKEN', token);
      return { token, ...oth };
    })
  )
);

export const doResetPassword = command((email: string) => Api.doResetPassword(email));

export const doChangePassword = command(
  ({ newPassword, token }: { newPassword: string; token: string }) =>
    Api.doChangePassword(newPassword, token)
);

export const setActiveScope = command(
  (scope: string) => fromIO(of(window.sessionStorage.setItem('activeScope', scope))),
  { getActiveScope }
);

export const setScopeTableHeight = command(
  ({ tableTag, nodeId }: { tableTag: string; nodeId: number }) =>
    fromIO(of(window.sessionStorage.setItem(`${tableTag}_node`, nodeId.toString())))
);

export const setTableSortingModel = command(
  ({ tableTag, sortModel }: { tableTag: string; sortModel: AgGridSortModel[] }) =>
    fromIO(
      of(window.sessionStorage.setItem(`${tableTag}_sorting_model`, JSON.stringify(sortModel)))
    )
);

export const doCleanStorage = command(
  flow(
    () => fromIO(of(window.localStorage.clear())),
    map(() => window.sessionStorage.clear())
  )
);

export const doLogout = command(
  flow(() => chainFromToken((token) => Api.doLogout(token)), chain(doCleanStorage))
);

export const doSetTransactionToUnsafe = command(
  ({ transactionId, scope }: { transactionId: string; scope: string }) =>
    chainFromToken((token) => Api.setTransactionAsUnsafe(token, transactionId, scope))
);

export const doSetTransactionToSafe = command(
  ({ transactionId, scope }: { transactionId: string; scope: string }) =>
    chainFromToken((token) => Api.setTransactionAsSafe(token, transactionId, scope))
);

export const doSetTransactionsToUnsafe = command(
  ({ transactionIds, scope }: { transactionIds: string[]; scope: string }) =>
    chainFromToken((token) => Api.setTransactionsAsUnsafe(token, transactionIds, scope))
);

export const doSetTransactionsToSafe = command(
  ({ transactionIds, scope }: { transactionIds: string[]; scope: string }) =>
    chainFromToken((token) => Api.setTransactionsAsSafe(token, transactionIds, scope))
);

export const createStaticRule = command(
  ({
    active,
    luceneQuery,
    ruleType,
    title,
    scope,
  }: Omit<FormOnSubmitProps, 'staticRule'> & { scope: string }) =>
    chainFromToken((token) =>
      Api.createStaticRule(scope, title, luceneQuery, active, ruleType, token)
    ),
  { getStaticRules }
);

export const updateStaticRule = command(
  ({ id, active, luceneQuery, rType, title, scope }: Rule) =>
    chainFromToken((token) =>
      Api.updateStaticRule(id, scope, title, luceneQuery, active, rType, token)
    ),
  { getStaticRules }
);

export const deleteStaticRule = command(
  ({ id, scope }: { id: RuleId; scope: string }) =>
    chainFromToken((token) => Api.deleteStaticRule(ruleIdIso.unwrap(id), scope, token)),
  { getStaticRules }
);

export const doAzureLogin = command(
  flow(
    Api.azureLogin,
    map(({ token, ...oth }) => {
      window.localStorage.setItem('AUTH_TOKEN', token);
      return { token, ...oth };
    })
  )
);
