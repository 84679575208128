import { config } from '#config';
import Mousetrap from 'mousetrap';
import React from 'react';

const mTrap = new Mousetrap(document.documentElement);

export const useIsMounted = () => {
  const isMounted = React.useRef(false);
  //@ts-ignore
  React.useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);
  return isMounted;
};

export const useListenEventKey = (
  keyListen: string | string[],
  callback: (e: Mousetrap.ExtendedKeyboardEvent, combo: string) => void
) => {
  React.useEffect(() => {
    mTrap.bind(keyListen, callback);
    return () => {
      mTrap.reset();
    };
  }, [callback, keyListen]);
};

export const useCheckScreenSize = () => {
  const [isMinSize, setIsMinSize] = React.useState(false);
  const checkSize = () => {
    const { innerHeight, innerWidth } = window;
    if (innerHeight < config.minHeight || innerWidth < config.minWidth) {
      setIsMinSize(true);
    } else {
      setIsMinSize(false);
    }
  };
  React.useEffect(() => {
    checkSize();
    window.addEventListener('resize', checkSize);
    return window.addEventListener('resize', checkSize);
  });
  return isMinSize;
};
