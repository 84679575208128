type A = 'chrome' | 'ie' | 'edgeHtml' | 'firefox' | 'safariDesktop' | 'safariIOS';
type SupportedBrowser = {
  [K in A]: {
    edgeVersionMin?: number;
    official: { min: number; max: number };
    unofficial: { min: number | null; max: number | null };
  };
};

const SUPPORTED_BROWSERS: SupportedBrowser = {
  chrome: {
    official: { min: 60, max: Infinity },
    unofficial: { min: 0, max: Infinity },
  },
  ie: {
    official: { min: 11, max: 11 },
    unofficial: { min: null, max: null },
  },
  edgeHtml: {
    edgeVersionMin: 31,
    official: { min: 16, max: Infinity },
    unofficial: { min: 0, max: Infinity },
  },
  firefox: {
    official: { min: 75, max: Infinity }, // never officially supported
    unofficial: { min: 0, max: Infinity },
  },
  safariDesktop: {
    official: { min: 11, max: Infinity }, // never officially supported
    unofficial: { min: 0, max: Infinity },
  },
  safariIOS: {
    official: { min: 11, max: Infinity },
    unofficial: { min: 0, max: Infinity },
  },
};

function chromeSupported(min: number, max: number) {
  return function (userAgent: string) {
    const f = parseInt((/Chrome\/(\d+)/.exec(userAgent) || [])[1] || '0');
    return min <= f && f <= max && !/Edge\//.test(userAgent) && !/OPR\//.test(userAgent);
  };
}
function edgeSupported(min: number, max: number) {
  return function (userAgent: string) {
    const f = parseInt((/Edge\/(\d+)/.exec(userAgent) || [])[1] || '0');
    return min <= f && f <= max;
  };
}
function desktopSafariSupported(min: number, max: number) {
  return function (userAgent: string) {
    const f = parseInt((/Version\/(\d+)/.exec(userAgent) || [])[1] || '0');
    return (
      /Safari/.exec(userAgent) &&
      min <= f &&
      f <= max &&
      !/Chrome\//.test(userAgent) &&
      !/Chromium\//.test(userAgent)
    );
  };
}
function mobileSafariIPadIOSSupported(min: number, max: number) {
  return function (userAgent: string) {
    const f = parseInt((/iPad.*CPU[a-zA-Z ]+(\d+)/.exec(userAgent) || [])[1] || '0');
    return /Mobile.*Safari/.exec(userAgent) && min <= f && f <= max;
  };
}
function firefoxSupported(min: number, max: number) {
  return function (userAgent: string) {
    const f = parseInt((/Firefox\/(\d+)/.exec(userAgent) || [])[1] || '0');
    return /firefox|iceweasel|fxios/i.exec(userAgent) && min <= f && f <= max;
  };
}
function ieSupported(min: number, max: number) {
  return function (userAgent: string) {
    const f = parseInt((/(?:msie |rv:)(\d+)/i.exec(userAgent) || [])[1] || '0');
    return /msie|trident/i.exec(userAgent) && min <= f && f <= max;
  };
}

const isBrowserSupported = (e: SupportedBrowser) => {
  var i = navigator.userAgent;
  return (
    !!chromeSupported(e.chrome.official.min, e.chrome.official.max)(i) ||
    !!ieSupported(e.ie.official.min, e.ie.official.max)(i) ||
    !!edgeSupported(e.edgeHtml.official.min, e.edgeHtml.official.max)(i) ||
    !!desktopSafariSupported(e.safariDesktop.official.min, e.safariDesktop.official.max)(i) ||
    !!mobileSafariIPadIOSSupported(e.safariIOS.official.min, e.safariIOS.official.max)(i) ||
    !!firefoxSupported(e.firefox.official.min, e.firefox.official.max)(i)
  );
};
export default () => {
  //@ts-ignore
  return isBrowserSupported(SUPPORTED_BROWSERS);
};
