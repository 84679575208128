// DO NOT EDIT MANUALLY - metarpheus-generated
import * as t from 'io-ts';
import axios from 'axios';
import { either, taskEither } from 'fp-ts';
// @ts-ignore
import { Option } from 'fp-ts/Option';
import { TaskEither } from 'fp-ts/TaskEither';
import { flow, pipe } from 'fp-ts/function';
// @ts-ignore
import { optionFromNullable } from 'io-ts-types/lib/optionFromNullable';
import * as m from './model-ts';

export interface RouteConfig {
  apiEndpoint: string;
  timeout: number;
}

export default function getRoutes(_metarpheusRouteConfig: RouteConfig) {
  return {
    transactionController_setAsSafe: function ({
      token,
      transactionId,
      scope,
    }: {
      token: string;
      transactionId: m.UUIDWrapper;
      scope: string;
      }): TaskEither<unknown, m.Acknowledged> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/transactions/setAsSafe`,
              params: {},
              data: {
                transactionId: m.UUIDWrapper.encode(transactionId),
                scope: t.string.encode(scope),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.Acknowledged.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    transactionController_setAsSafeBatch: function ({
      token,
      transactionIds,
      scope,
    }: {
      token: string;
      transactionIds: Array<m.UUIDWrapper>;
      scope: string;
    }): TaskEither<unknown, number> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/transactions/setAsSafeBatch`,
              params: {},
              data: {
                transactionIds: t.array(m.UUIDWrapper).encode(transactionIds),
                scope: t.string.encode(scope),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.Integer.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    transactionController_setAsUnsafe: function ({
      token,
      transactionId,
      scope,
    }: {
      token: string;
      transactionId: m.UUIDWrapper;
      scope: string;
      }): TaskEither<unknown, m.Acknowledged> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/transactions/setAsUnsafe`,
              params: {},
              data: {
                transactionId: m.UUIDWrapper.encode(transactionId),
                scope: t.string.encode(scope),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.Acknowledged.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    transactionController_setAsUnsafeBatch: function ({
      token,
      transactionIds,
      scope,
    }: {
      token: string;
      transactionIds: Array<m.UUIDWrapper>;
      scope: string;
    }): TaskEither<unknown, number> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/transactions/setAsUnsafeBatch`,
              params: {},
              data: {
                transactionIds: t.array(m.UUIDWrapper).encode(transactionIds),
                scope: t.string.encode(scope),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.Integer.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    transactionController_getTimeWindow: function ({
      token,
      scope,
    }: {
      token: string;
      scope: string;
    }): TaskEither<unknown, m.TimeWindow> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/transactions/getTimeWindow`,
              params: {
                scope: t.string.encode(scope),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.TimeWindow.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    transactionController_getTransactions: function ({
      token,
      scope,
      risk,
      fradulentType,
      userId,
      offset,
      limit,
      groupByField,
      orderBy,
    }: {
      token: string;
      scope: string;
      risk: m.Risk;
      fradulentType: m.FraudulentType;
      userId: string | undefined;
      offset: number;
      limit: number;
      groupByField: string | undefined;
      orderBy: Array<m.OrderByField>;
    }): TaskEither<unknown, m.TransactionsQueryResult<m.BobafettTransaction>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/transactions/getTransactions`,
              params: {
                scope: t.string.encode(scope),
                risk: m.Risk.encode(risk),
                fradulentType: m.FraudulentType.encode(fradulentType),
                userId: t.union([t.string, t.undefined]).encode(userId),
                offset: t.Integer.encode(offset),
                limit: t.Integer.encode(limit),
                groupByField: t.union([t.string, t.undefined]).encode(groupByField),
                orderBy: t.array(m.OrderByField).encode(orderBy),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.TransactionsQueryResult(m.BobafettTransaction).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    transactionController_getTransaction: function ({
      token,
      scope,
      id,
    }: {
      token: string;
      scope: string;
      id: m.UUIDWrapper;
    }): TaskEither<unknown, m.BobafettTransaction> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/transactions/getTransaction`,
              params: {
                scope: t.string.encode(scope),
                id: m.UUIDWrapper.encode(id),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.BobafettTransaction.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    transactionController_getScoreContributions: function ({
      token,
      scope,
      id,
    }: {
      token: string;
      scope: string;
      id: m.UUIDWrapper;
    }): TaskEither<unknown, m.ScoreContributions> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/transactions/getScoreContributions`,
              params: {
                scope: t.string.encode(scope),
                id: m.UUIDWrapper.encode(id),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.ScoreContributions.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    transactionController_searchTransactions: function ({
      token,
      scope,
      query,
      limit,
      offset,
      groupByField,
      orderBy,
    }: {
      token: string;
      scope: string;
      query: string;
      limit: number | undefined;
      offset: number;
      groupByField: string | undefined;
      orderBy: Array<m.OrderByField>;
    }): TaskEither<unknown, m.TransactionsQueryResult<m.BobafettTransaction>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/transactions/searchTransactions`,
              params: {
                scope: t.string.encode(scope),
                query: t.string.encode(query),
                limit: t.union([t.Integer, t.undefined]).encode(limit),
                offset: t.Integer.encode(offset),
                groupByField: t.union([t.string, t.undefined]).encode(groupByField),
                orderBy: t.array(m.OrderByField).encode(orderBy),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.TransactionsQueryResult(m.BobafettTransaction).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    authController_login: function ({
      username,
      password,
      token,
    }: {
      username: string;
      password: string;
      token: string | undefined;
    }): TaskEither<unknown, m.LoginResult> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/auth/login`,
              params: {},
              data: {
                username: t.string.encode(username),
                password: t.string.encode(password),
                token: t.union([t.string, t.undefined]).encode(token),
              },
              headers: {
                'Content-Type': 'application/json',
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.LoginResult.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    authController_refreshToken: function ({
      token,
    }: {
      token: string;
    }): TaskEither<unknown, m.Auth> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/auth/refreshToken`,
              params: {},
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.Auth.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    authController_recoverLogin: function ({
      token,
      newPassword,
    }: {
      token: string;
      newPassword: string;
    }): TaskEither<unknown, void> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/auth/recoverLogin`,
              params: {},
              data: {
                newPassword: t.string.encode(newPassword),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.VoidFromUnit.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    authController_registerForRecoveryFromEmail: function ({
      email,
    }: {
      email: string;
    }): TaskEither<unknown, string> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/auth/registerForRecoveryFromEmail`,
              params: {},
              data: {
                email: t.string.encode(email),
              },
              headers: {
                'Content-Type': 'application/json',
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.string.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    authController_isSessionValid: function ({
      token,
    }: {
      token: string;
    }): TaskEither<unknown, boolean> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/auth/isSessionValid`,
              params: {},
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.boolean.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    authController_logout: function ({ token }: { token: string }): TaskEither<unknown, void> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/auth/logout`,
              params: {},
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.VoidFromUnit.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    authController_getUserInfo: function ({
      token,
    }: {
      token: string;
    }): TaskEither<unknown, m.UserInfo> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/auth/getUserInfo`,
              params: {},
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.UserInfo.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    authController_changePassword: function ({
      token,
      oldPassword,
      newPassword,
    }: {
      token: string;
      oldPassword: string;
      newPassword: string;
    }): TaskEither<unknown, void> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/auth/changePassword`,
              params: {},
              data: {
                oldPassword: t.string.encode(oldPassword),
                newPassword: t.string.encode(newPassword),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.VoidFromUnit.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    authController_azureSSO: function ({
      jwt,
    }: {
      jwt: string;
    }): TaskEither<unknown, m.LoginResult> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/auth/azureSSO`,
              params: {},
              data: {
                jwt: t.string.encode(jwt),
              },
              headers: {
                'Content-Type': 'application/json',
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.LoginResult.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    confController_getScopeConfiguration: function ({
      token,
      scope,
      lang,
    }: {
      token: string;
      scope: string;
      lang: string;
    }): TaskEither<unknown, m.FrontendScopeConfiguration> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/conf/getScopeConfiguration`,
              params: {
                scope: t.string.encode(scope),
                lang: t.string.encode(lang),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.FrontendScopeConfiguration.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    confController_getFrontendConfig: function ({}: {}): TaskEither<
      unknown,
      m.FrontendConfiguration
    > {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/conf/getFrontendConfig`,
              params: {},
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.FrontendConfiguration.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    confController_getUserViewConfiguration: function ({
      token,
      lang,
      scopeGroup,
    }: {
      token: string;
      lang: string;
      scopeGroup: string;
    }): TaskEither<unknown, m.FrontendUserViewConfiguration> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/conf/getUserViewConfiguration`,
              params: {
                lang: t.string.encode(lang),
                scopeGroup: t.string.encode(scopeGroup),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.FrontendUserViewConfiguration.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_getAuditLogs: function ({
      token,
      event,
      from,
      to,
    }: {
      token: string;
      event: m.AuditEvent | undefined;
      from: m.LocalDateTimeWrapper | undefined;
      to: m.LocalDateTimeWrapper | undefined;
    }): TaskEither<unknown, Array<m.AuditLog>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/getAuditLogs`,
              params: {
                event: t.union([m.AuditEvent, t.undefined]).encode(event),
                from: t.union([m.LocalDateTimeWrapper, t.undefined]).encode(from),
                to: t.union([m.LocalDateTimeWrapper, t.undefined]).encode(to),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.array(m.AuditLog).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_getUsers: function ({
      token,
      groupId,
    }: {
      token: string;
      groupId: m.GroupId | undefined;
    }): TaskEither<unknown, Array<m.User>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/getUsers`,
              params: {
                groupId: t.union([m.GroupId, t.undefined]).encode(groupId),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.array(m.User).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_getClients: function ({
      token,
    }: {
      token: string;
    }): TaskEither<unknown, Array<m.User>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/getClients`,
              params: {},
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.array(m.User).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_createUser: function ({
      token,
      username,
      email,
      groupId,
      defaultPassword,
      userType,
      permissions,
    }: {
      token: string;
      username: string;
      email: string;
      groupId: m.GroupId | undefined;
      defaultPassword: string;
      userType: m.UserLoginType;
      permissions: Array<m.PermissionId>;
    }): TaskEither<unknown, m.UserId> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/createUser`,
              params: {},
              data: {
                username: t.string.encode(username),
                email: t.string.encode(email),
                groupId: t.union([m.GroupId, t.undefined]).encode(groupId),
                defaultPassword: t.string.encode(defaultPassword),
                userType: m.UserLoginType.encode(userType),
                permissions: t.array(m.PermissionId).encode(permissions),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.UserId.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_editUser: function ({
      token,
      userId,
      newGroupId,
      userType,
      permissions,
    }: {
      token: string;
      userId: m.UserId;
      newGroupId: m.GroupId | undefined;
      userType: m.UserLoginType;
      permissions: Array<m.PermissionId>;
    }): TaskEither<unknown, m.UserId> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/editUser`,
              params: {},
              data: {
                userId: m.UserId.encode(userId),
                newGroupId: t.union([m.GroupId, t.undefined]).encode(newGroupId),
                userType: m.UserLoginType.encode(userType),
                permissions: t.array(m.PermissionId).encode(permissions),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.UserId.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_deleteUser: function ({
      token,
      userId,
    }: {
      token: string;
      userId: m.UserId;
    }): TaskEither<unknown, m.UserId> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/deleteUser`,
              params: {},
              data: {
                userId: m.UserId.encode(userId),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.UserId.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_getGroups: function ({
      token,
    }: {
      token: string;
    }): TaskEither<unknown, Array<m.Group>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/getGroups`,
              params: {},
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.array(m.Group).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_createGroup: function ({
      token,
      name,
      permissions,
    }: {
      token: string;
      name: string;
      permissions: Array<m.PermissionId>;
    }): TaskEither<unknown, m.GroupId> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/createGroup`,
              params: {},
              data: {
                name: t.string.encode(name),
                permissions: t.array(m.PermissionId).encode(permissions),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.GroupId.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_changeGroupPermissions: function ({
      token,
      groupId,
      permissions,
    }: {
      token: string;
      groupId: m.GroupId;
      permissions: Array<m.PermissionId>;
    }): TaskEither<unknown, m.GroupId> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/changeGroupPermissions`,
              params: {},
              data: {
                groupId: m.GroupId.encode(groupId),
                permissions: t.array(m.PermissionId).encode(permissions),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.GroupId.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_changeClientPermissions: function ({
      token,
      clientId,
      permissions,
    }: {
      token: string;
      clientId: m.UserId;
      permissions: Array<m.PermissionId>;
    }): TaskEither<unknown, void> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/changeClientPermissions`,
              params: {},
              data: {
                clientId: m.UserId.encode(clientId),
                permissions: t.array(m.PermissionId).encode(permissions),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.VoidFromUnit.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_deleteGroup: function ({
      token,
      groupId,
    }: {
      token: string;
      groupId: m.GroupId;
    }): TaskEither<unknown, m.GroupId> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/deleteGroup`,
              params: {},
              data: {
                groupId: m.GroupId.encode(groupId),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.GroupId.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_getCompanyPermissions: function ({
      token,
      userId,
      groupId,
    }: {
      token: string;
      userId: m.UserId | undefined;
      groupId: m.GroupId | undefined;
    }): TaskEither<unknown, Array<m.CompanyPermission>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/getCompanyPermissions`,
              params: {
                userId: t.union([m.UserId, t.undefined]).encode(userId),
                groupId: t.union([m.GroupId, t.undefined]).encode(groupId),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.array(m.CompanyPermission).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_resetPassword: function ({
      token,
      userId,
      newPassword,
    }: {
      token: string;
      userId: m.UserId;
      newPassword: string;
    }): TaskEither<unknown, m.UserId> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/resetPassword`,
              params: {},
              data: {
                userId: m.UserId.encode(userId),
                newPassword: t.string.encode(newPassword),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.UserId.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_registerClient: function ({
      token,
      userType,
      permissions,
    }: {
      token: string;
      userType: m.UserLoginType;
      permissions: Array<m.PermissionId>;
    }): TaskEither<unknown, m.ClientCredentials> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/registerClient`,
              params: {},
              data: {
                userType: m.UserLoginType.encode(userType),
                permissions: t.array(m.PermissionId).encode(permissions),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.ClientCredentials.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_unregisterClient: function ({
      token,
      clientId,
    }: {
      token: string;
      clientId: string;
    }): TaskEither<unknown, void> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/unregisterClient`,
              params: {},
              data: {
                clientId: t.string.encode(clientId),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.VoidFromUnit.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    adminController_getJsonSchema: function ({
      token,
      scope,
    }: {
      token: string;
      scope: string;
    }): TaskEither<unknown, m.JsonWrapper> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/admin/getJsonSchema`,
              params: {
                scope: t.string.encode(scope),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.JsonWrapper.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    statisticController_getStatistics: function ({
      token,
      scope,
      lang,
    }: {
      token: string;
      scope: string;
      lang: string;
    }): TaskEither<unknown, Array<m.Chart>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/stats/getStatistics`,
              params: {
                scope: t.string.encode(scope),
                lang: t.string.encode(lang),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.array(m.Chart).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    healthController_status: function ({}: {}): TaskEither<unknown, string> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/health/status`,
              params: {},
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.string.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    staticRuleController_getAll: function ({
      token,
      scope,
    }: {
      token: string;
      scope: string;
    }): TaskEither<unknown, Array<m.Rule>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/staticRule/getAll`,
              params: {
                scope: t.string.encode(scope),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.array(m.Rule).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    staticRuleController_get: function ({
      token,
      id,
      scope,
    }: {
      token: string;
      id: number;
      scope: string;
    }): TaskEither<unknown, m.Rule> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/staticRule/get`,
              params: {
                id: t.Integer.encode(id),
                scope: t.string.encode(scope),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.Rule.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    staticRuleController_saveRule: function ({
      token,
      title,
      luceneQuery,
      active,
      rType,
      scope,
    }: {
      token: string;
      title: string;
      luceneQuery: string;
      active: boolean;
      rType: m.RuleType;
      scope: string;
    }): TaskEither<unknown, number> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/staticRule/saveRule`,
              params: {},
              data: {
                title: t.string.encode(title),
                luceneQuery: t.string.encode(luceneQuery),
                active: t.boolean.encode(active),
                rType: m.RuleType.encode(rType),
                scope: t.string.encode(scope),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.Integer.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    staticRuleController_updateRule: function ({
      token,
      id,
      title,
      luceneQuery,
      active,
      rType,
      scope,
    }: {
      token: string;
      id: number;
      title: string;
      luceneQuery: string;
      active: boolean;
      rType: m.RuleType;
      scope: string;
    }): TaskEither<unknown, number> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/staticRule/updateRule`,
              params: {},
              data: {
                id: t.Integer.encode(id),
                title: t.string.encode(title),
                luceneQuery: t.string.encode(luceneQuery),
                active: t.boolean.encode(active),
                rType: m.RuleType.encode(rType),
                scope: t.string.encode(scope),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.Integer.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    staticRuleController_deleteRule: function ({
      token,
      id,
      scope,
    }: {
      token: string;
      id: number;
      scope: string;
    }): TaskEither<unknown, number> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'post',
              url: `${_metarpheusRouteConfig.apiEndpoint}/staticRule/deleteRule`,
              params: {},
              data: {
                id: t.Integer.encode(id),
                scope: t.string.encode(scope),
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.Integer.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    histogramController_getCustomerProfileHistogram: function ({
      token,
      customerId,
      scope,
      feature,
    }: {
      token: string;
      customerId: string;
      scope: string;
      feature: string;
    }): TaskEither<unknown, m.CustomerProfileHistogramApiResponse> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/histogram/getCustomerProfileHistogram`,
              params: {
                customerId: t.string.encode(customerId),
                scope: t.string.encode(scope),
                feature: t.string.encode(feature),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.CustomerProfileHistogramApiResponse.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    histogramController_getFeatures: function ({
      token,
      scope,
    }: {
      token: string;
      scope: string;
    }): TaskEither<unknown, Array<string>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/histogram/getFeatures`,
              params: {
                scope: t.string.encode(scope),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.array(t.string).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    userController_getAll: function ({
      token,
      onlyFlagged,
      offset,
      limit,
      userSearchPattern,
      subject,
    }: {
      token: string;
      onlyFlagged: boolean;
      offset: number;
      limit: number;
      userSearchPattern: string | undefined;
      subject: string;
    }): TaskEither<unknown, Array<m.BobafettUser>> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/users/getAll`,
              params: {
                onlyFlagged: t.boolean.encode(onlyFlagged),
                offset: t.Integer.encode(offset),
                limit: t.Integer.encode(limit),
                userSearchPattern: t.union([t.string, t.undefined]).encode(userSearchPattern),
                subject: t.string.encode(subject),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            t.array(m.BobafettUser).decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    userController_getTemporalFlags: function ({
      token,
      userId,
      subject,
    }: {
      token: string;
      userId: string;
      subject: string;
    }): TaskEither<unknown, m.UserTemporalFlags> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/users/getTemporalFlags`,
              params: {
                userId: t.string.encode(userId),
                subject: t.string.encode(subject),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.UserTemporalFlags.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    userController_getUserInfo: function ({
      token,
      userId,
      subject,
    }: {
      token: string;
      userId: string;
      subject: string;
    }): TaskEither<unknown, m.BobafettUserInfo> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/users/getUserInfo`,
              params: {
                userId: t.string.encode(userId),
                subject: t.string.encode(subject),
              },
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.BobafettUserInfo.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },

    userController_getTemporalTimeWindow: function ({
      token,
    }: {
      token: string;
    }): TaskEither<unknown, m.TimeWindow> {
      return pipe(
        taskEither.tryCatch(
          () =>
            axios({
              method: 'get',
              url: `${_metarpheusRouteConfig.apiEndpoint}/users/getTemporalTimeWindow`,
              params: {},
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache, no-store',
                Authorization: `Token token="${token}"`,
              },
              timeout: _metarpheusRouteConfig.timeout,
            }),
          (v): unknown => v
        ),
        taskEither.map((res) => res.data),
        taskEither.chainEitherK(
          flow(
            m.TimeWindow.decode,
            either.mapLeft((e): unknown => e)
          )
        )
      );
    },
  };
}
