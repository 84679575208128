import { config } from '#config';
import qs from 'qs';
import { generatePath } from 'react-router-dom';

export const getAzureLogoutApi = () => {
  const params = qs.stringify({
    post_logout_redirect_uri: `${window.location.origin}${generatePath(config.routesPath.LOGIN)}/`,
  });
  return `https://login.microsoftonline.com/common/oauth2/v2.0/logout?${params}`;
};

export const getAzureLoginApi = () => {
  const params = qs.stringify({
    response_type: 'id_token',
    redirect_uri: `${window.location.origin}${generatePath(config.routesPath.AZURE_REDIRECT)}/`,
    response_mode: 'fragment',
    nonce: '678910',
    client_id: '0db58479-2d2a-4397-b5d7-39d4b9260bea',
    scope: 'openid profile',
  });
  return `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?${params}`;
};
